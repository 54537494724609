import {
  Box,FormControl, InputLabel, MenuItem, Select,
  SelectChangeEvent, TextField, Typography
} from "@mui/material"
import styles from './AdminLeave.module.scss'
import React, { useState } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import EnhancedTable from "./AdminLeaveTable";
import DateRangeIcon from '@mui/icons-material/DateRange';
import { AddEditLeaveDg } from "./CustomDialogs";

interface adminLeaveProp {
  open: boolean;
}

const AdminLeave: React.FC<adminLeaveProp> = ({ open }) => {
  const [leaveType, setLeaveType] = useState<string>('')
  const [leaveStatus, setLeaveStatus] = useState<string>('')
  const [openAddleave, setAddLeave] = useState<HTMLElement | null>(null)
  const openAdd = Boolean(openAddleave)
  const handleClose = () => {
    setAddLeave(null)
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAddLeave(openAddleave ? null : event.currentTarget);
  }
  const handleChange = (e: SelectChangeEvent) => {
    setLeaveType(e.target.value as string)
  }
  const handleChangeStatus = (e: SelectChangeEvent) => {
    setLeaveStatus(e.target.value as string)
  }

  return (
    <>
      <Box className={` ${open ? styles.open : styles.adminLeaveMain_Container}`}>
        <div className={styles.alHeader}>
          <div>
            <Typography variant="h4">Leaves</Typography>
            <ul className={styles.headingSubText}>
              <li><a href="/dashboard">Dashboard </a></li>
              <li><a href="/"> / leaves</a></li>
            </ul>
          </div>
          <div>
            <button
              className={styles.hadingBtn}
              onClick={handleClick}>
              <span>+</span>
              Add Leave
            </button>
          </div>
        </div>
        <div className={styles.leaveType}>
          <div className={styles.diff_leaveType}>
            <Typography
              variant='body1'>
              Today Present
            </Typography>

            <Typography
              variant="h6">
              12/60
            </Typography>
          </div>
          <div className={styles.diff_leaveType}>
            <Typography
              variant='body1'>
              Planned Leave
            </Typography>

            <Typography
              variant="h6">
              6
              <span>Today</span>
            </Typography>
          </div>
          <div className={styles.diff_leaveType}>
            <Typography
              variant='body1'>
              Unplannes Leaves
            </Typography>

            <Typography
              variant="h6">
              0
              <span>Today</span>
            </Typography>

          </div>
          <div className={styles.diff_leaveType}>
            <Typography
              variant='body1'>
              Panding Request
            </Typography>

            <Typography
              variant="h6">
              12
            </Typography>

          </div>
        </div>
        <div className={styles.leaveData}>
          <div className={styles.leaveDataElem}>
            <TextField
              placeholder="Employee Name"
              sx={{ width: '100%' }} />
          </div>
          <div className={styles.leaveDataElem}>
            <FormControl sx={{ width: '100%' }}>
              <InputLabel>Leave Type</InputLabel>
              <Select
                value={leaveType}
                label='Leave Type'
                onChange={handleChange}
              >
                <MenuItem value='Casual Leave'>Casual Leave</MenuItem>
                <MenuItem value='Medical Leave'>Medical Leave</MenuItem>
                <MenuItem value='Loss Of Pay'>Loss Of Pay</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className={styles.leaveDataElem}>
            <FormControl sx={{ width: '100%' }} >
              <InputLabel>Leave Status</InputLabel>
              <Select
                value={leaveStatus}
                label='Leave Status'
                onChange={handleChangeStatus}
              >
                <MenuItem value='Approved'>Approved</MenuItem>
                <MenuItem value='Panding'>Panding</MenuItem>
                <MenuItem value='Decline'>Decline</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className={styles.leaveDataElem}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <i className={styles.dateIcon}><DateRangeIcon /></i>
              <DatePicker
                label="From"
                slotProps={{ textField: { sx: { width: '100%' } } }}
              />

            </LocalizationProvider>
          </div>
          <div className={styles.leaveDataElem}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <i className={styles.dateIcon}><DateRangeIcon /></i>
              <DatePicker
                label="To"
                slotProps={{ textField: { sx: { width: '100%' } } }} />
            </LocalizationProvider>
          </div>
          <div className={styles.leaveDataElem}>
            <button className={styles.searchButton}>
              SEARCH
            </button>
          </div>

        </div>
        <div className={styles.showEmpData}>
          <EnhancedTable />
        </div>
        <AddEditLeaveDg
          heading='Add Leaves'
          openAdd={openAdd}
          handleClose={handleClose} />
      </Box>
    </>
  )
}

export default AdminLeave


