
import React, { useState } from 'react';
import { Box, Select, MenuItem, ListItemIcon } from '@mui/material';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import SearchIcon from '@mui/icons-material/Search';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import AdminDropdown from './AdminDropdown';
import MenuIcon from '@mui/icons-material/Menu';
import eng from './asset/english1.png';
import spanish from './asset/spanish.png';
import french from './asset/french.png';
import german from './asset/german.png';
import "./HeaderStyle.scss";

interface headerProps {
  toggleDrawer: () => void;
}

const Header: React.FC<headerProps> = ({ toggleDrawer }) => {
  const languages = [
    { value: 'English', abbreviation: 'en', label: 'English', image: eng },
    { value: 'French', abbreviation: 'fr', label: 'French', image: french },
    { value: 'German', abbreviation: 'de', label: 'German', image: german },
    { value: 'Spanish', abbreviation: 'es', label: 'Spanish', image: spanish },
  ];

  const [selectedLanguage, setSelectedLanguage] = useState('English');
  const handleLanguageChange = (event: any) => {
    setSelectedLanguage(event.target.value);
  };

  const [showNotifications, setShowNotifications] = useState(false);
  const [notifications, setNotifications] = useState([
    { id: 1, text: 'Notification 1', imageUrl: 'notification1.jpg' },
    { id: 2, text: 'Notification 2', imageUrl: 'notification2.jpg' },
    { id: 3, text: 'Notification 3', imageUrl: 'notification3.jpg' },
  ]);

  const toggleNotifications = () => {
    setShowNotifications(prevState => !prevState);
  };

  const clearAllNotifications = () => {
    setNotifications([]);
    setShowNotifications(false);
  };

  const [showMessages, setShowMessages] = useState(false);
  const [messages, setMessages] = useState([
    { id: 1, text: 'Message 1', imageUrl: 'message1.jpg' },
    { id: 2, text: 'Message 2', imageUrl: 'message2.jpg' },
  ]);

  const toggleMessages = () => {
    setShowMessages(prevState => !prevState);
  };

  const clearAllMessages = () => {
    setMessages([]);
    setShowMessages(false);
  };

  const handleMyProfileClick = () => {
    // Handle My Profile click
  };

  const handleSettingsClick = () => {
    // Handle Settings click
  };

  const handleLogoutClick = () => {
    // Handle Logout click
  };

  const [active, setActive] = React.useState(true);
  const [showAdminDropdown, setShowAdminDropdown] = useState(false);
  const toggleAdminDropdown = () => {
    setShowAdminDropdown(prevState => !prevState);
  };

  return (
    <Box className="main-div">
      <div className='icon'>
        <i onClick={toggleDrawer}> <MenuIcon /></i>
      </div>
      <Box className="logo">
        <a><h1>Logo</h1></a>
      </Box>
      <Box className='menu-icon'>
        <i onClick={toggleDrawer}>  <MenuOpenIcon /></i>

      </Box>
      <h3>Dreams Technologies</h3>
      <Box component="ul" className="nav user-menu">
        <Box component="li" className="nav-item">
          <Box className="top-nav-search">
            <input className="search-input" type="text" placeholder="Search here" />
            <button className="btn" type="submit">
              <SearchIcon />
            </button>
          </Box>
        </Box>
        <Box component="li">
          <Box className="language-main">
            <Select className='MuiSelect-root' value={selectedLanguage} onChange={handleLanguageChange}>
              {languages.map(language => (
                <MenuItem key={language.value} value={language.value} className='MuiMenuItem-root'>
                  <ListItemIcon className='MuiListItemIcon-root'>
                    <img src={language.image} alt={language.label} height="15px" width="20px" style={{ margin: "10px" }} />
                  </ListItemIcon>
                  {selectedLanguage === language.value ? language.abbreviation : language.label}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
        <Box component="li">
          <Box className="notification-dropdown">
            <Box className="main-div1">
              <Box className="inner-div">
                <span className="notification-icon" onClick={toggleNotifications}>
                  <NotificationsNoneIcon />
                  {notifications.length > 0 && <span className="notification-count">{notifications.length}</span>}
                </span>
              </Box>
              {showNotifications && (
                <Box className="notification-list">
                  <Box className="heading">
                    <span>Notification</span>
                    <a className="notification-a" href="#" onClick={clearAllNotifications}>Clear All</a>
                  </Box>
                  <ul>
                    {notifications.map(notification => (
                      <li key={notification.id}>
                        <a href="#">
                          <img src={notification.imageUrl} alt="Notification" />
                          <Box className="notification-details">
                            <p>{notification.text}</p>
                          </Box>
                        </a>
                      </li>
                    ))}
                  </ul>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        <Box component="li">
          <Box className="message-dropdown" marginRight={"30px"}>
            <Box className="msg-div2">
              <Box className="msg-inner-div">
                <span className="message-icon" onClick={toggleMessages}>
                  <MessageOutlinedIcon />
                  {messages.length > 0 && <span className="msg-count">{messages.length}</span>}
                </span>
              </Box>
              {showMessages && (
                <Box className="msg-list">
                  <Box className="heading1">
                    <span>Message</span>
                    <a className="msg-a" href="#" onClick={clearAllMessages}>Clear All</a>
                  </Box>
                  <ul>
                    {messages.map(message => (
                      <li key={message.id}>
                        <a href="#">
                          <img src={message.imageUrl} alt="Message" />
                          <Box className="msg-details">
                            <p>{message.text}</p>
                          </Box>
                        </a>
                      </li>
                    ))}
                  </ul>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        <Box component="li">
          <AdminDropdown
            onMyProfileClick={handleMyProfileClick}
            onSettingsClick={handleSettingsClick}
            onLogoutClick={handleLogoutClick}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Header;
