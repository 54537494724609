import React from 'react'
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { BarChart } from '@mui/x-charts/BarChart';
import styles from "./Employee.module.scss";
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import avt from './emp.jpg'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import { deepOrange, deepPurple } from '@mui/material/colors';
import empimg from './employee-img.png';

interface deaprops {
  open: boolean
}

const Employee:React.FC<deaprops> = ({ open }) => {
  const [age, setAge] = React.useState('');

  const handleChange = (event: SelectChangeEvent) => {
    setAge(event.target.value as string);
  };
  
  return (
    <div>
      
    <div className={` ${open ? styles.open : styles.Empmain}`}>
      <div className={styles.main}>
      <div className={styles.Empsub}>
      <div className={styles.flexcontainer}>
      <div className={styles.set}><p className={styles.txt}>Welcome Back,Darlee<br></br>
       You have 4 meetings today,</p><br>
       </br>
       <Button sx={{backgroundColor:"white",color:"black"}} variant="contained" className={styles.btn}>View Profile</Button>
  </div>

  <div> <Avatar  alt="Remy Sharp" src={avt} /></div>

</div>
{/* statistic */}

<div className={styles.statistic}>
<div className={styles.stat}><div><h2>Statistic</h2></div>

<Box sx={{ minWidth: 120 }}>
<FormControl sx={{ m:0, minWidth: 120 }} size="small">
      <InputLabel id="demo-select-small-label">Today</InputLabel>
      <Select
        labelId="demo-select-small-label"
        id="demo-select-small"
       
        label="Today"
        onChange={handleChange}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        <MenuItem value={10}>Week</MenuItem>
        <MenuItem value={20}>Month</MenuItem>
        <MenuItem value={30}>Year</MenuItem>
      </Select>
    </FormControl>
    </Box>
   
</div>
<div className={styles.stat2}>
  <div>Work Time<br></br>
   <h4> 6 Hrs : 54 Min</h4>
  </div>
  <div> <Button style={{backgroundColor:"darkorange",color:"white",marginTop:8}} startIcon={<PlayArrowOutlinedIcon />}>Clock-In</Button>
</div>
</div>
  <div className={styles.stat3}>
    <div>Remaining<br></br>
      2 Hrs 36 Min
    </div>
    <div>Overtime<br></br>
      0 Hrs 00 Min
    </div>
    <div>Break<br></br>
      1 Hrs 20 Min
    </div>
  </div>
  <hr></hr>
  <h4>View Attendance
  </h4>
  </div>
{/* holidays */}
  <div className={styles.holiday}>
   <div> <h2 style={{color:"white"}}>Upcoming Holidays</h2></div>
   <div className={styles.holiday1}>
     <div><span style={{color:"white"}}><CalendarMonthOutlinedIcon fontSize='large'/></span></div>
     <div><p style={{color:"white"}}>Ramzan<br></br>
          Mon 20 May 2024</p></div>
          <div></div>
          <div style={{marginLeft:70,marginTop:5}}> 
            <Button sx={{backgroundColor:"white",color:"black",width:100}}>View All</Button>
          </div>    
   </div>
  </div>
  
   
      </div>
     
      {/* //new column */}
      <div className={styles.Empsub}>
        <div className={styles.box1}>
          <div className={styles.stat}>
          <div className={styles.AL}><h4>Attendance & Leaves</h4></div>
          <div className={styles.AL2}>
          <Box sx={{ minWidth: 120 }}>
<FormControl sx={{ m:0, minWidth: 120 }} size="small">
      <InputLabel id="demo-select-small-label">2024</InputLabel>
      <Select
        labelId="demo-select-small-label"
        id="demo-select-small"
        
        label="Today"
        onChange={handleChange}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        <MenuItem value={10}>2025</MenuItem>
        <MenuItem value={20}>2026</MenuItem>
        <MenuItem value={30}>2027</MenuItem>
      </Select>
    </FormControl>
          </Box>
          </div>  
          </div>

              <div className={styles.stat}>
                    <div><p style={{color:"orange",fontSize:20,fontWeight:700}}>9</p>
                         Total Leaves</div>
                    <div><p style={{color:"#b33b72",fontSize:20,fontWeight:700}}>5.5</p>
                         Leaves Taken</div>
                    <div><p style={{color:"green",fontSize:20,fontWeight:700}}>04</p>
                         Leaves absent</div>
              </div>

              <div className={styles.stat}>
                  <div><p style={{color:"purple",fontSize:20,fontWeight:700}}>0</p>
                         Total Leaves</div>
                    <div><p style={{color:"blue",fontSize:20,fontWeight:700}}>214</p>
                         Leaves Taken</div>
                    <div><p style={{color:"red",fontSize:20,fontWeight:700}}>02</p>
                         Leaves absent</div>
              </div>

                      <hr></hr>  

                  <h4>Apply Leave</h4>
        </div>

        <div className={styles.box1}>
        <div className={styles.stat}>
        <div className={styles.AL}><h4>Working Hours</h4></div>
        <div className={styles.AL2}>
          <Box sx={{ minWidth: 120 }}>
<FormControl sx={{ m:0, minWidth: 120 }} size="small">
      <InputLabel id="demo-select-small-label">This Week</InputLabel>
      <Select
        labelId="demo-select-small-label"
        id="demo-select-small"
        
        label="Today"
        onChange={handleChange}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        <MenuItem value={10}>Last Week</MenuItem>
        <MenuItem value={20}>This Month</MenuItem>
        <MenuItem value={30}>Last 30 Days</MenuItem>
      </Select>
    </FormControl>
          </Box>
        </div> 
        </div> 
    <div>
          <BarChart className={styles.chart}
      xAxis={[{ scaleType: 'band', data: ['group A', 'group B', 'group C'] }]}
      series={[{ data: [4,2,3] }, { data: [1, 6, 3] }, { data: [2, 5, 6] }]}
      width={350}
      height={250}
           />
    </div>
      </div>
      </div>

      {/* new column */}
      <div className={styles.Empsub}>
        <div className={styles.largebox}>
          <div className={styles.stat}>
            <div style={{fontWeight:700}}>Important</div>
            <div></div>
            <div className={styles.view} style={{fontWeight:700}}>View All &nbsp;
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
              <circle cx="12" cy="12" r="10"></circle>
              <polyline points="12 16 16 12 12 8"></polyline>
              <line x1="8" y1="12" x2="16" y2="12"></line></svg>
            </div>
          </div>
                <br></br>
          
          <div className={styles.stat}>
          <div className={styles.view}style={{fontWeight:700,marginLeft:40}}>Notifications</div>
          <div className={styles.view}style={{fontWeight:700}}>Schedules</div>  
          </div>  
          
            <div className={styles.longbox}>
            <Avatar sx={{ margin:'15px 12px',width:'2rem',padding:'1.3rem',height:'2rem' }} >N</Avatar>&nbsp;
                <p className={styles.view} style={{fontWeight:500,padding:10}}>Your Leave Request Has Been<br></br>
                10:30 AM 21 Apr 2024</p>
            </div>

            <div className={styles.longbox}>
            <Avatar sx={{ margin:'15px 12px',width:'2rem',padding:'1.3rem',height:'2rem' }}>H</Avatar>&nbsp;
                <p className={styles.view}style={{fontWeight:500,padding:10}}>Your Leave Request Has Been<br></br>
                10:30 AM 21 Apr 2024</p>
            </div>

            <div className={styles.longbox}>
            <Avatar sx={{ margin:'15px 12px',width:'2rem',padding:'1.3rem',height:'2rem' }} >N</Avatar>&nbsp;
                <p className={styles.view}style={{fontWeight:500,padding:10}}>Your Leave Request Has Been<br></br>
                10:30 AM 21 Apr 2024</p>
            </div>

            <div className={styles.longbox}>
            <Avatar sx={{ margin:'15px 12px',width:'2rem',padding:'1.3rem',height:'2rem' }} >P</Avatar>&nbsp;
                <p className={styles.view}style={{fontWeight:500,padding:10}}>Your Leave Request Has Been<br></br>
                10:30 AM 21 Apr 2024</p>
            </div>

            <div className={styles.longbox}>
                <Avatar  alt="Remy Sharp" src={avt} style={{width:65,height:65,padding:10,marginLeft:'5px',marginTop:'5px'}}/>&nbsp;
                <p className={styles.view}style={{fontWeight:500,padding:10}}>Your Leave Request Has Been<br></br>
                10:30 AM 21 Apr 2024</p>
            </div>

            <div className={styles.longbox}>
            <Avatar sx={{ margin:'15px 12px',width:'2rem',padding:'1.3rem',height:'2rem' }} >J</Avatar>&nbsp;
                <p className={styles.view}style={{fontWeight:500,padding:10}}>Your Leave Request Has Been<br></br>
                10:30 AM 21 Apr 2024</p>
            </div>
        </div>
      </div>
      </div>
      </div>

      {/* square box */}
    <div className={` ${open ? styles.open : styles.newdiv}`}>
      <h2 style={{marginLeft:'50px'}}>On Going Projects</h2><br></br>
        <div className={styles.newflexcon}>
        {/* 1st box */}
            <div>
                <p className={styles.header}>Deadline:10 Feb 2024</p><br></br>
                <h4 style={{color:"black"}}>Office Management</h4>
                <h4 style={{color:"black"}}>Creating an online platform that enables various administrative tasks within an organizations</h4>
                <div className={styles.graybox}>
                    <div><h3 style={{color:"black",fontWeight:600,marginTop:5}} >20<br></br><p style={{fontSize:'15px',fontWeight:450}}>Total Tasks</p></h3></div>
                    <div><h3 style={{color:"black",fontWeight:600,marginTop:5}}>12<br></br><p style={{fontSize:'15px',fontWeight:450}}>Total Completed</p></h3></div>
                </div>
                <div className={styles.pleader}>
                <div><h3 style={{color:"black",fontSize:18}}>Project Leader: </h3></div>
                <div style={{marginLeft:'20px'}}><Avatar  alt="Remy Sharp" src={avt} /></div>
                </div>

                <div className={styles.pleader}>
                <div><h3 style={{color:"black",fontSize:18}}>Members: </h3></div>
                <div style={{marginLeft:'20px'}}>
                <Stack direction="row" spacing={-1}>
                  <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" sx={{width:30,height:30}} />
                  <Avatar alt="Travis Howard" src="/static/images/avatar/2.jpg" sx={{width:30,height:30}}/>
                  <Avatar alt="Cindy Baker" src="/static/images/avatar/3.jpg" sx={{width:30,height:30}}/>
                </Stack>
                </div>
                </div>

            </div>

          {/* 2nd box */}
           <div>
                <p className={styles.header}>Deadline:10 Feb 2024</p><br></br>
                <h4 style={{color:"black"}}>Video Calling App</h4>
                <h4>Design and developing a software application that enables users to make video calls over the internet.</h4>
                <div className={styles.graybox}>
                <div><h3 style={{color:"black",fontWeight:600,marginTop:5}} >20<br></br><p style={{fontSize:'15px',fontWeight:450}}>Total Tasks</p></h3></div>
                <div><h3 style={{color:"black",fontWeight:600,marginTop:5}}>12<br></br><p style={{fontSize:'15px',fontWeight:450}}>Total Completed</p></h3></div>
                </div>
                <div className={styles.pleader}>
                <div><h3 style={{color:"black",fontSize:18}}>Project Leader: </h3></div>
                <div style={{marginLeft:'20px'}}><Avatar  alt="Remy Sharp" src={avt} /></div>
                </div>

                <div className={styles.pleader}>
                <div><h3 style={{color:"black",fontSize:18}}>Members: </h3></div>
                <div style={{marginLeft:'20px'}}>
                <Stack direction="row" spacing={-1}>
                  <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" sx={{width:30,height:30}} />
                  <Avatar alt="Travis Howard" src="/static/images/avatar/2.jpg" sx={{width:30,height:30}}/>
                  <Avatar alt="Cindy Baker" src="/static/images/avatar/3.jpg" sx={{width:30,height:30}}/>
                </Stack>
                </div>
                </div>

            </div>

            {/* 3rd box */}
            <div>
                <p className={styles.header}>Deadline:10 Feb 2024</p><br></br>
                <h4 style={{color:"black"}}>Hospital Administration</h4>
                <h4>Creating an online platform that serves as a central hub for hospital admin, staff, patients.</h4>
                <div className={styles.graybox}>
                <div><h3 style={{color:"black",fontWeight:600,marginTop:5}} >20<br></br><p style={{fontSize:'15px',fontWeight:450}}>Total Tasks</p></h3></div>
                <div><h3 style={{color:"black",fontWeight:600,marginTop:5}}>12<br></br><p style={{fontSize:'15px',fontWeight:450}}>Total Completed</p></h3></div>
                </div>
                <div className={styles.pleader}>
                <div><h3 style={{color:"black",fontSize:18}}>Project Leader: </h3></div>
                <div style={{marginLeft:'20px'}}><Avatar  alt="Remy Sharp" src={avt} /></div>
                </div>

                <div className={styles.pleader}>
                <div><h3 style={{color:"black",fontSize:18}}>Members: </h3></div>
                <div style={{marginLeft:'20px'}}>
                <Stack direction="row" spacing={-1}>
                  <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" sx={{width:30,height:30}}/>
                  <Avatar alt="Travis Howard" src="/static/images/avatar/2.jpg" sx={{width:30,height:30}}/>
                  <Avatar alt="Cindy Baker" src="/static/images/avatar/3.jpg" sx={{width:30,height:30}}/>
                </Stack>
                </div>
                </div>

            </div> 
          
        </div>
        {/* Last box */}
        <div className={styles.lastflex}>
             <div className={styles.pinkbox}>
                <div>
                  <p className={styles.text}>Employee Of The Month</p>
                  <p>We are really proud of the difference you have made which
                     gives everybody the reason to applaud & appreciate</p><br>
                     </br><br></br>
                     <h4> congrats,Henna</h4><br></br>
                     <h4>UI/UX Team Lead</h4>
                   </div>
              <div className={styles.divimg}><img src={empimg}></img></div>
              
            </div>

              
        <div className={styles.cmp} style={{backgroundColor:"white"}}>
            <div>Company Policy
              <div className={styles.innerbox}>
                    <div>
                      <div className={styles.HR}>
                        <div><Avatar sx={{ width:40,height:40,margin:'10px' }} >HR</Avatar></div>
                        <div><h3 style={{marginTop:'13px'}}>HR Policy</h3></div>
                      </div>
                         <h5>Policy Name:Work Policy</h5>
                         <h5>Updated on:Sunday</h5>
                    </div>
                    <div>
                      <div className={styles.HR}>
                        <div><Avatar sx={{ width:40,height:40,margin:'10px' }} >HR</Avatar></div>
                        <div><h3 style={{marginTop:'13px'}}>Employer Policy</h3></div>
                      </div>
                         <h5>Policy Name:Parking</h5>
                         <h5>Updated on:25 Jan 2024</h5>
                    </div>
              </div>
             
              
            </div> 
        </div>


            
        </div>

</div>

    </div>






    
  )
}

export default Employee