import {
    Box, Button, Dialog, DialogContent, FormControl, styled,
    Pagination, Stack, Table, TableBody, TableCell, TableContainer,
    TableHead, TablePagination, TableRow, TableSortLabel, TextField, 
    Typography, Select,  MenuItem,  SelectChangeEvent,
} from '@mui/material'
import styles from './Designations.module.scss'
import React, { useEffect, useMemo, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import { CustomPopover, DeleteDialog } from '../AdminLeave/CustomDialogs';
import { visuallyHidden } from '@mui/utils';
import MoreVertIcon from '@mui/icons-material/MoreVert';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
    id: keyof Data;
    label: string;
}

const headCells: readonly HeadCell[] = [
    { id: 'id', label: '#', },
    { id: 'department', label: 'Department ', },
    { id: 'designation', label: 'Designation', },
    { id: 'action', label: 'Action', },
];
interface EnhancedTableProps {
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler =
        (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

    return (
        <TableHead>
            <TableRow className={styles.tableHeading}>
                {headCells.map((headCell) => (
                    <TableCell
                        sx={{ fontWeight: 600 }}
                        align='left'
                        key={headCell.id}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const StyledTableRow = styled(TableRow)(() => ({
    '&:nth-of-type(odd)': {
        backgroundColor: "#e2e4e6",
    },
    '&:nth-of-type(even)': {
        backgroundColor: "white",
    },
}));

interface Data {
    id: number;
    department: string;
    designation: string;
    action: React.ReactNode;

}

const createData = (id: number, department: string, designation: string, action: string) => ({
    id,
    department,
    designation,
    action
});


const Designation: React.FC<{ open: boolean }> = ({ open }) => {
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<keyof Data>('id');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [openPopover, setPopover] = useState<null | HTMLElement>(null);
    const [opendelete, setDelete] = useState<boolean>(false)
    const [AddDesign, setAddDesign] = useState<HTMLElement | null>(null)
    const [EditDesign, setEditDesign] = useState<HTMLElement | null>(null)
    const [popoverPosition, setPopoverPosition] = useState({ top: 0, left: 0 });
    const [editRowId, setEditRowId] = useState<number | null>(null);

    let rows = [createData(1, 'Web Development', 'Web Developer', ''),
    createData(2, 'Mobile Development', 'Mobile Developer', '')
    ];

    const clickToAdd = (event: React.MouseEvent<HTMLElement>) => {
        setAddDesign(AddDesign ? null : event.currentTarget);
    }

    //add Dialog
    const openAddDg = Boolean(AddDesign)

    //for popover
    const openPop = Boolean(openPopover);
    const handleOpenPopover = (event: React.MouseEvent<HTMLElement>, id: number) => {
        const rect = event.currentTarget.getBoundingClientRect();
        setPopoverPosition({
            top: rect.bottom,
            left: rect.left
        });
        setEditRowId(id);
        setPopover(openPopover ? null : event.currentTarget);
    };

    const openEdit = (event: React.MouseEvent<HTMLElement>) => {
        setEditDesign(EditDesign ? null : event.currentTarget);
    };

    const openDeleteDg = () => {
        setDelete(true);
    }

    //edit dialog
    const openeditdg = Boolean(EditDesign)

    //delete DialogBox
    const delateData = () => {

    };

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof Data,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleStackPaginationClick = (event: unknown | null, newPage: number) => {
        setPage(newPage - 1); // Adjust newPage to match zero-indexed page state
    };


    const visibleRows = useMemo(
        () =>
            stableSort(rows, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, page, rowsPerPage],
    );
    return (
        <>
            <Box
                className={` ${open ? styles.open : styles.department_Container}`}>
                <div className={styles.alHeader}>
                    <div>
                        <Typography variant="h5">
                            Designations
                        </Typography>
                        <ul className={styles.headingSubText}>
                            <li><a href="/dashboard">Dashboard </a></li>
                            <li><a href="/"> / Designations</a></li>
                        </ul>
                    </div>
                    <div>
                        <button
                            className={styles.hadingBtn}
                            onClick={clickToAdd}>
                            <span>+</span>
                            Add Designation
                        </button>
                    </div>
                </div>
                <Box sx={{ width: '100%' }}>
                    <div className={styles.tableHeader}>
                        <p>show</p>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={() => { }}
                            onRowsPerPageChange={() => { }}
                            sx={{
                                '.MuiTablePagination-spacer': { display: 'none' },
                                '.MuiToolbar-root': { paddingLeft: 0, minHeight: 0 },
                                '.MuiTablePagination-actions': { display: 'none' },
                                '.MuiTablePagination-displayedRows': { display: 'none' },
                                '.MuiTablePagination-selectLabel': { display: 'none' },
                            }}
                        />
                        <p>Entries</p>
                    </div>
                    <TableContainer>
                        <Table
                            sx={{ minWidth: 750 }}
                        >
                            <EnhancedTableHead
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                                rowCount={rows.length}
                            />
                            <TableBody>
                                {visibleRows.map((row, index) => {
                                    return (
                                        <StyledTableRow data-id={row.id}>
                                            <TableCell
                                                align='left'
                                                scope="row"
                                            >
                                                {row.id}
                                            </TableCell>
                                            <TableCell align="left">{row.department}</TableCell>
                                            <TableCell align="left">{row.designation}</TableCell>
                                            <TableCell align="left">
                                                <i onClick={(e) => { handleOpenPopover(e, row.id) }}><MoreVertIcon /></i>
                                            </TableCell>
                                            <CustomPopover
                                                openPopover={openPop}
                                                closePopover={() => setPopover(null)}
                                                openEdit={openEdit}
                                                openDeleteDg={openDeleteDg}
                                                position={popoverPosition}
                                            />
                                        </StyledTableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <div style={{ float: 'right' }}>
                        <Stack spacing={2}>
                            <Pagination
                                count={Math.ceil(rows.length / rowsPerPage)}
                                page={page + 1} 
                                onChange={handleStackPaginationClick}
                                variant="outlined"
                                shape="rounded"
                                hidePrevButton
                                hideNextButton
                                className={styles.usepagination}
                            />
                        </Stack>
                    </div>
                </Box >
            </Box>
            <AddEditDesignDg
                heading='Add Designation'
                opendg={openAddDg}
                handleClose={() => setAddDesign(null)}
            />
            <AddEditDesignDg
                heading='Edit Designation'
                opendg={openeditdg}
                handleClose={()=>setEditDesign(null)}
                initialDesignation={rows.filter(row => row.id === editRowId)[0]?.designation || ''}
            />
            <DeleteDialog
                heading='Delete Designation'
                opendelete={opendelete}
                closeDeleteDg={() => setDelete(false)}
                delateData={delateData} />
        </>
    )
}

export default Designation

interface addEditProps {
    heading: string;
    opendg: boolean;
    handleClose: () => void;
    initialDesignation?: string;

}

const AddEditDesignDg = ({ heading, opendg, handleClose, initialDesignation }: addEditProps) => {
    const [department, setDepartment] = useState<string>('');
    const [designation, setDesignation] = useState<string>('');

    useEffect(() => {
        setDesignation(initialDesignation as string);
    }, [initialDesignation]);

    const handleChange = (event: SelectChangeEvent) => {
        setDepartment(event.target.value as string);
    };

    return (
        <Dialog
            open={opendg}
            onClose={handleClose}
            className={styles.dgwidth}

            sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "500px",
                    },
                },
            }}
        >
            <DialogContent
                sx={{
                    '&.MuiDialogContent-root': {
                        overflowX: 'hidden'
                    }
                }}>
                <FormControl sx={{
                    '&.MuiFormControl-root': {
                        width: "100%",
                    }
                }}>
                    <Box
                        className={styles.dialogHeader}>
                        <Typography
                            variant="h5"
                            sx={{ fontWeight: 500, margin: '.5rem' }}>
                            {heading}
                        </Typography>
                        <i onClick={handleClose}>
                            <CloseIcon />
                        </i>
                    </Box>
                    <Typography
                        variant="body1"
                        sx={{ marginTop: '.5rem' }}>
                        Designation Name
                        <span>*</span>
                    </Typography>
                    <TextField
                        value={designation}
                        onChange={(e) => setDesignation(e.target.value)}
                    />
                    <Typography
                        variant="body1"
                        sx={{ marginTop: '.5rem' }}>
                        Departments
                        <span>*</span>
                    </Typography>
                    <Select
                        value={department}
                        onChange={handleChange}
                        displayEmpty
                    >
                        <MenuItem value="">Select Department</MenuItem>
                        <MenuItem value='Web Development'>Web Development</MenuItem>
                        <MenuItem value='It Management'>IT Management</MenuItem>
                        <MenuItem value='Marketing'>Marketing</MenuItem>
                    </Select>
                    <Button
                        className={styles.dialogBoxBtn}
                    >Submit</Button>
                </FormControl>
            </DialogContent>
        </Dialog>
    )
}
