import React from 'react'
import styles from "./Overtime.module.scss";
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import Overtimetable from './Overtimetable';

interface deaprops {
    open: boolean
}

const Overtime :React.FC<deaprops> = ({ open }) => {
    const navigate = useNavigate();
   
    function navi()
    {
        navigate("Admin-dashboard")
    }

  return (
    <>
        <div className={` ${open ? styles.open : styles.overtimemain}`}>
            <div className={styles.leavediv}>
                <div className={styles.innerleavediv}>
                <span className={styles.leavetxt}>Overtime</span><br></br>
                <p onClick={navi} style={{cursor:"pointer"}}>Dashboard / Leaves</p>
                </div>
                <div className={styles.addbtn}>
                <Button variant="contained" size="medium" 
                    sx={{backgroundColor:"darkorange",
                    borderRadius:'20px',
                    width:'150px',
                    fontWeight:600}}>
                    + Add Overtime
                </Button>
                </div>
            </div>

                        {/* Row Section */}
                    <div className={styles.row}>
                        <div className={styles.statsinfo}>
                            <h6 style={{fontWeight:450}}>Overtime Employee</h6>
                            <h4 style={{fontWeight:650}}>12 <sub style={{fontWeight:400,fontSize:'11px'}}>this month</sub></h4>
                        </div>
                        <div className={styles.statsinfo}>
                            <h6 style={{fontWeight:450}}>Overtime Hours</h6>
                            <h4 style={{fontWeight:650}}>118 <sub style={{fontWeight:400,fontSize:'11px'}}>this month</sub></h4>
                        </div>
                        <div className={styles.statsinfo}>
                            <h6 style={{fontWeight:450}}>Pending Request</h6>
                            <h4 style={{fontWeight:650}}>23</h4>
                        </div>
                        <div className={styles.statsinfo}>
                            <h6 style={{fontWeight:450}}>Rejected</h6>
                            <h4 style={{fontWeight:650}}>5</h4>
                        </div>
                    </div>

                            <Overtimetable />
        </div>
    </>
  )
}

export default Overtime