import {
    Box, Button, Dialog, DialogContent, FormControl, styled,
    Pagination, Stack, Table, TableBody, TableCell, TableContainer,
    TableHead, TablePagination, TableRow, TableSortLabel, Typography,
    TextField,
    TextareaAutosize,
    Select,
    SelectChangeEvent,
    MenuItem
} from '@mui/material'
import styles from './Timesheet.module.scss'
import React, { useMemo, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import { CustomPopover, DeleteDialog } from '../AdminLeave/CustomDialogs';
import { visuallyHidden } from '@mui/utils';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import DateRangeIcon from '@mui/icons-material/DateRange';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
    id: keyof Data;
    label: string;
}

const headCells: readonly HeadCell[] = [
    { id: 'name', label: 'Name ', },
    { id: 'date', label: 'Date', },
    { id: 'project', label: 'Projects', },
    { id: 'assignedhr', label: 'Assigned Hours', },
    { id: 'hour', label: 'Hours', },
    { id: 'description', label: 'Description', },
    { id: 'action', label: 'Action', },
];
interface EnhancedTableProps {
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler =
        (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

    return (
        <TableHead>
            <TableRow className={styles.tableHeading}>
                {headCells.map((headCell) => (
                    <TableCell
                        sx={{ fontWeight: 600 }}
                        align='left'
                        key={headCell.id}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const StyledTableRow = styled(TableRow)(() => ({
    '&:nth-of-type(odd)': {
        backgroundColor: "#e2e4e6",
    },
    '&:nth-of-type(even)': {
        backgroundColor: "white",
    },
}));

interface Data {
    id: number;
    name: string;
    date: string,
    project: string,
    assignedhr: number,
    hour: number,
    description: string,
    action: React.ReactNode,

}

const createData = (id: number, name: string, date: string, project: string, assignedhr: number, hour: number, description: string, action: string) =>
({
    id,
    name,
    date,
    project,
    assignedhr,
    hour,
    description,
    action
});


const Timesheet: React.FC<{ open: boolean }> = ({ open }) => {
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<keyof Data>('id');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [openPopover, setPopover] = useState<null | HTMLElement>(null);
    const [opendelete, setDelete] = useState<boolean>(false)
    const [AddSheet, setAddSheet] = useState<HTMLElement | null>(null)
    const [EditSheet, setEditSheet] = useState<HTMLElement | null>(null)
    const [popoverPosition, setPopoverPosition] = useState({ top: 0, left: 0 });
    const [editRowId, setEditRowId] = useState<number | null>(null);

    let rows = [createData(1, 'john deo', ' 02 june 2001', 'ems', 3, 3, 'hello', '')];

    const clickToAdd = (event: React.MouseEvent<HTMLElement>) => {
        setAddSheet(AddSheet ? null : event.currentTarget);
    }

    //add Dialog
    const openAddDg = Boolean(AddSheet)

    //for popover
    const openPop = Boolean(openPopover);
    const handleOpenPopover = (event: React.MouseEvent<HTMLElement>, id: number) => {
        const rect = event.currentTarget.getBoundingClientRect();
        setPopoverPosition({
            top: rect.bottom,
            left: rect.left
        });
        setEditRowId(id);
        setPopover(openPopover ? null : event.currentTarget);
    };

    const openEdit = (event: React.MouseEvent<HTMLElement>) => {
        console.log(editRowId)
        setEditSheet(EditSheet ? null : event.currentTarget);
    };

    const openDeleteDg = () => {
        setDelete(true);
    }

    //edit dialog
    const openeditdg = Boolean(EditSheet)

    //delete DialogBox
    const delateData = () => {

    };

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof Data,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleStackPaginationClick = (event: unknown | null, newPage: number) => {
        setPage(newPage - 1); // Adjust newPage to match zero-indexed page state
    };


    const visibleRows = useMemo(
        () =>
            stableSort(rows, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, page, rowsPerPage],
    );
    return (
        <>
            <Box
                className={` ${open ? styles.open : styles.timesheet_Container}`}>
                <div className={styles.alHeader}>
                    <div>
                        <Typography variant="h5">
                            Time Sheet
                        </Typography>
                        <ul className={styles.headingSubText}>
                            <li><a href="/dashboard">Dashboard </a></li>
                            <li><a href="/"> / Time Sheet</a></li>
                        </ul>
                    </div>
                    <div>
                        <button
                            className={styles.hadingBtn}
                            onClick={clickToAdd}>
                            <span>+</span>
                            Add Today Work
                        </button>
                    </div>
                </div>
                <Box sx={{ width: '100%' }}>
                    <div className={styles.tableHeader}>
                        <p>show</p>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={() => { }}
                            onRowsPerPageChange={() => { }}
                            sx={{
                                '.MuiTablePagination-spacer': { display: 'none' },
                                '.MuiToolbar-root': { paddingLeft: 0, minHeight: 0 },
                                '.MuiTablePagination-actions': { display: 'none' },
                                '.MuiTablePagination-displayedRows': { display: 'none' },
                                '.MuiTablePagination-selectLabel': { display: 'none' },
                            }}
                        />
                        <p>Entries</p>
                    </div>
                    <TableContainer>
                        <Table
                            sx={{ minWidth: 750 }}
                        >
                            <EnhancedTableHead
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                                rowCount={rows.length}
                            />
                            <TableBody>
                                {visibleRows.map((row) => {
                                    return (
                                        <StyledTableRow data-id={row.id}>
                                            <TableCell
                                                align='left'
                                                scope="row"
                                            >
                                                {row.name}
                                            </TableCell>
                                            <TableCell align="left">{row.date}</TableCell>
                                            <TableCell align="left">{row.project}</TableCell>
                                            <TableCell align="left">{row.assignedhr}</TableCell>
                                            <TableCell align="left">{row.hour}</TableCell>
                                            <TableCell align="left">{row.description}</TableCell>
                                            <TableCell align="left">
                                                <i onClick={(e) => { handleOpenPopover(e, row.id) }}><MoreVertIcon /></i>
                                            </TableCell>
                                            <CustomPopover
                                                openPopover={openPop}
                                                closePopover={() => setPopover(null)}
                                                openEdit={openEdit}
                                                openDeleteDg={openDeleteDg}
                                                position={popoverPosition}
                                            />
                                        </StyledTableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <div style={{ float: 'right' }}>
                        <Stack spacing={2}>
                            <Pagination
                                count={Math.ceil(rows.length / rowsPerPage)}
                                page={page + 1}
                                onChange={handleStackPaginationClick}
                                variant="outlined"
                                shape="rounded"
                                hidePrevButton
                                hideNextButton
                                className={styles.usepagination}
                            />
                        </Stack>
                    </div>
                </Box >
            </Box>

            <DeleteDialog
                heading='Delete Time Sheet'
                opendelete={opendelete}
                closeDeleteDg={() => setDelete(false)}
                delateData={delateData} />

            <AddEditSheetDg
                heading='Add Today Work Detail'
                opendg={openAddDg}
                handleClose={() => setAddSheet(null)}
            />
            <AddEditSheetDg
                heading='Edit Today Work Detail'
                opendg={openeditdg}
                handleClose={()=>setEditSheet(null)}

            />
        </>
    )
}

export default Timesheet

interface addEditProps {
    heading: string;
    opendg: boolean;
    handleClose: () => void;

}

const AddEditSheetDg = ({ heading, opendg, handleClose }: addEditProps) => {
    const [project, setProject] = useState<string>('Office Management')
    const handleChangeProject = (event: SelectChangeEvent) => {
        setProject(event.target.value)
    }
    return (
        <Dialog
            open={opendg}
            onClose={handleClose}
            className={styles.dgwidth}

            sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "800px",
                    },
                },
            }}
        >
            <DialogContent
                sx={{
                    '&.MuiDialogContent-root': {
                        overflowX: 'hidden'
                    }
                }}>
                <FormControl sx={{
                    '&.MuiFormControl-root': {
                        width: "100%",
                    }
                }}>
                    <Box
                        className={styles.dialogDflex}>
                        <Typography
                            variant="h5"
                            sx={{ fontWeight: 500, margin: '.5rem' }}>
                            {heading}
                        </Typography>
                        <i onClick={handleClose}>
                            <CloseIcon />
                        </i>
                    </Box>
                    <Typography
                        variant="body2"
                        sx={{ marginTop: '.5rem' }}>
                        Project
                        <span>*</span>
                    </Typography>

                    <Select
                        value={project}
                        onChange={handleChangeProject}
                    >
                        <MenuItem value="Office Management">Office Management</MenuItem>
                        <MenuItem value='Project Management'>Project Management</MenuItem>
                        <MenuItem value='Video Calling App'>Video Calling App</MenuItem>
                        <MenuItem value='Hospital Administration'>Hospital Administration</MenuItem>
                    </Select>

                    <div className={styles.dialogDflex}>
                        <div>
                            <Typography
                                variant="body2"
                                sx={{ marginTop: '.5rem' }}>
                                Deadline
                                <span>*</span>
                            </Typography>
                            <LocalizationProvider
                                dateAdapter={AdapterDayjs} >
                                <DatePicker disabled />
                            </LocalizationProvider>
                        </div>
                        <div>
                            <Typography
                                variant="body2"
                                sx={{ marginTop: '.5rem' }}>
                                Total Hour
                                <span>*</span>
                            </Typography>
                            <TextField disabled />
                        </div>
                        <div>
                            <Typography
                                variant="body2"
                                sx={{ marginTop: '.5rem' }}>
                                Remaining Hour
                                <span>*</span>
                            </Typography>
                            <TextField disabled />
                        </div>
                    </div>
                    <div className={styles.dgDateHr}>
                        <div>
                            <Typography
                                variant="body2"
                                sx={{ marginTop: '.5rem' }}>
                                Date
                                <span>*</span>
                            </Typography >
                            <LocalizationProvider
                                dateAdapter={AdapterDayjs}>
                                <DatePicker
                                />
                                <i className={styles.dateicondg}><DateRangeIcon /></i>
                            </LocalizationProvider>
                        </div>
                        <div>
                            <Typography
                                variant="body2"
                                sx={{ marginTop: '.5rem' }}>
                                Remaining Hour
                                <span>*</span>
                            </Typography>
                            <TextField required />
                        </div>
                    </div>
                    <Typography
                        variant="body2"
                        sx={{ margin: '1rem 0rem' }}>
                        Description
                        <span>*</span>
                    </Typography>
                    <TextareaAutosize minRows={9} />
                    <Button
                        className={styles.dialogBoxBtn}
                    >Submit</Button>
                </FormControl>
            </DialogContent>
        </Dialog>
    )
}
