import { Box, Typography } from '@mui/material'
import styles from './ShiftShedule.module.scss'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { SheduleHeader } from '../ShiftList/ShiftList'


const ShiftShedule: React.FC<{ open: boolean }> = ({ open }) => {

    const navigate = useNavigate();

    const clickToAssign = (event: React.MouseEvent<HTMLElement>) => {
        console.log('ckicked')
    }
    const goToShiftList=()=>{
        navigate('/shift-list');
    }
    return (
        <>
            <Box
                className={` ${open ? styles.open : styles.Shedule_Container}`}>
                <SheduleHeader heading='Shift Shedule' clickToAdd={clickToAssign} clickOnShift={goToShiftList} />
            </Box>
        </>
    )
}
export default ShiftShedule


