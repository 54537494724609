import { useMemo, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import {
    Avatar, Box, FormControl, MenuItem, Pagination,
    Select, SelectChangeEvent, Stack, Dialog, DialogContent,
    Popover, Typography,
    styled
} from '@mui/material';
import styles from './LeaveEmployee.module.scss'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { CustomDialog } from './LeaveEmployee';
//import { CustomDialog } from './AdminLeave';


function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
    id: keyof Data;
    label: string;
}

const headCells: readonly HeadCell[] = [
    { id: 'employee', label: 'Employee', },
    //{ id: 'leaveType', label: 'Leave Type', },
    { id: 'from', label: 'From', },
    { id: 'to', label: 'To', },
    { id: 'noOfDay', label: 'No of Days', },
    { id: 'reason', label: 'Reason', },
    { id: 'status', label: 'Status', },
    { id: 'action', label: 'Action', },
];
interface EnhancedTableProps {
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler =
        (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

    return (
        <TableHead>
            <TableRow sx={{backgroundColor:'#fff'}}>
                {headCells.map((headCell) => (
                    <TableCell
                        align='center'
                        key={headCell.id}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const StyledTableRow = styled(TableRow)(() => ({
    '&:nth-of-type(odd)': {
      backgroundColor: "#e2e4e6",
    },
    '&:nth-of-type(even)': {
      backgroundColor: "white",
    },
  }));

interface Data {
    //id: number;
    
    employee: string;
    //leaveType: string;
    from: string;
    to: string;
    noOfDay: string,
    reason: string;
    status: React.ReactNode;
    action: React.ReactNode;
}

function createData(
    //id: number,
    
    employee: string,
    //leaveType: string,
    from: string,
    to: string,
    noOfDay: string,
    reason: string,
    status: string,
    action: React.ReactNode,
): Data {
    return {
        //id,
        
        employee,
        //leaveType,
        from,
        to,
        noOfDay,
        reason,
        status,
        action,
    };
}
const diffStatus = ['New', 'Panding', 'Approved', 'Declined']

export default function EnhancedTable() {
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<keyof Data>('employee');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [selectStatus, setStatus] = useState<string>('New')
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [opendelete, setDelete] = useState<boolean>(false)
    const [openAddleave, setAddLeave] = useState<HTMLElement | null>(null)
    const openAdd = Boolean(openAddleave)
    const handleClose = () => {
        setAddLeave(null)
    }

    const handleClickOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const open = Boolean(anchorEl);

    const handleChange = (event: SelectChangeEvent) => {
        setStatus(event.target.value);
    };

    const openDeleteDg = () => {
        setDelete(true);
        setAnchorEl(null)

    }
    const openEdit = (event: React.MouseEvent<HTMLElement>) => {
        setAddLeave(openAddleave ? null : event.currentTarget);
        setAnchorEl(null)

    }
    const delateData = (id: number) => {
        // const updatedRows = rows.filter((row:any) => row.id !== id);
        // setDelete(false); 
        // setAnchorEl(null); 
        // rows = updatedRows;
    };

    let rows: any = [
        createData('LOP','24-Feb-2023', '25-Feb-2023', '2 days', 'Personnal', 'new',
            <i onClick={handleClickOpen}><MoreVertIcon /></i>),
        createData('Casual Leave','30-Jan-2023', '31-Jan-2023', '2 days', 'Personnal', 'new',
            <i onClick={handleClickOpen}><MoreVertIcon /></i>),
        createData('Medical Leave','27-Feb-2023', '27-Feb-2023', '1 day', 'Going To Hospital', 'new',
            <i onClick={handleClickOpen}><MoreVertIcon /></i>),
        createData('Hospitalisation','15-jan-2023', '25-Jan-2023', '1 day', 'Going To Hospital', 'new',
            <i onClick={handleClickOpen}><MoreVertIcon /></i>),
        createData('Medical Leave','27-Feb-2023', '27-Feb-2023', '1 day', 'Going To Hospital', 'new',
            <i onClick={handleClickOpen}><MoreVertIcon /></i>),
            createData('LOP','24-Feb-2023', '25-Feb-2023', '2 days', 'Personnal', 'new',
                <i onClick={handleClickOpen}><MoreVertIcon /></i>),
            createData('Casual Leave','30-Jan-2023', '31-Jan-2023', '2 days', 'Personnal', 'new',
                <i onClick={handleClickOpen}><MoreVertIcon /></i>),
            createData('Medical Leave','27-Feb-2023', '27-Feb-2023', '1 day', 'Going To Hospital', 'new',
                <i onClick={handleClickOpen}><MoreVertIcon /></i>),
            createData('Hospitalisation','15-jan-2023', '25-Jan-2023', '1 day', 'Going To Hospital', 'new',
                <i onClick={handleClickOpen}><MoreVertIcon /></i>),
            createData('Medical Leave','27-Feb-2023', '27-Feb-2023', '1 day', 'Going To Hospital', 'new',
                <i onClick={handleClickOpen}><MoreVertIcon /></i>),
            
    ];
    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof Data,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const handleStackPaginationClick = (event: unknown | null, newPage: number) => {
        setPage(newPage - 1); // Adjust newPage to match zero-indexed page state
    };


    const visibleRows = useMemo(
        () =>
            stableSort(rows, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, page, rowsPerPage],
    );

    return (
        <>
            <Box sx={{ width: '100%' }}>
                <div className={styles.tableHeader}>
                    <p>show</p>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        sx={{
                            '.MuiTablePagination-spacer': {
                                display: 'none',
                            },
                            '.MuiToolbar-root': {
                                paddingLeft: 0,
                                minHeight: 2,
                            },
                            '.MuiTablePagination-actions': {
                                display: 'none',
                            },
                            '.MuiTablePagination-displayedRows': {
                                display: 'none',
                            },
                            '.MuiTablePagination-selectLabel': {
                                display: 'none',
                            },

                        }}
                    />
                    <p>Entries</p>
                </div>
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                    >
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                        />
                        <TableBody>
                            {visibleRows.map((row, index) => {
                                // const labelId = enhanced-table-checkbox-${index};
                                return (
                                    <StyledTableRow >
                                        <TableCell
                                            align="center"
                                            // id={labelId}
                                            scope="row"

                                        >
                                          {row.employee}

                                        </TableCell>
                                        {/* <TableCell align="center">{row.leaveType}</TableCell> */}
                                        <TableCell align="center">{row.from}</TableCell>
                                        <TableCell align="center">{row.to}</TableCell>
                                        <TableCell align="center">{row.noOfDay}</TableCell>
                                        <TableCell align="center">{row.reason}</TableCell>
                                        <TableCell align="center">
                                            <FormControl>
                                                <Select
                                                    sx={{ height: '2rem', borderRadius: '3rem', '.MuiSelect-select': { display: 'flex' } }}
                                                    value={selectStatus}
                                                    onChange={handleChange}
                                                >
                                                    {diffStatus.map((item: string, index: number) => (
                                                        <MenuItem
                                                            value={item}
                                                            key={index}
                                                        >
                                                            <RadioButtonCheckedIcon
                                                                className={`${item === 'Approved' ? styles.approved : ''}
                                                          ${item === 'New' ? styles.new : ''}      
                                                          ${item === 'Panding' ? styles.panding : ''}
                                                          ${item === 'Approved' ? styles.approved : ''}
                                                          ${item === 'Declined' ? styles.declined : ''}`
                                                                }
                                                            />
                                                            {item}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </TableCell>
                                        <TableCell align="center">{row.action}</TableCell>

                                    </StyledTableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div style={{ float: 'right' }}>
                    <Stack spacing={2}>
                        <Pagination
                            count={Math.ceil(rows.length / rowsPerPage)}
                            page={page + 1} // Adjust page for 1-indexed display
                            onChange={handleStackPaginationClick}
                            variant="outlined"
                            shape="rounded"
                            hidePrevButton
                            hideNextButton
                            className={styles.pagination}
                            sx={{
                                '& .MuiPaginationItem-root': {
                                    '&:hover': {
                                        color: 'violet',
                                    },
                                    '&.Mui-selected': {
                                        backgroundColor: 'orange',
                                        color: 'white'
                                    },
                                },
                            }}
                        />
                    </Stack>
                </div>

            </Box >
            <Popover
                id={rows.id}
                open={open}
                onClose={() => setAnchorEl(null)}
                anchorEl={anchorEl}
            >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <button
                        className={styles.popupBtn}
                        onClick={openEdit}>
                        <i><ModeEditIcon /> </i>
                        Edit
                    </button>
                    <button
                        className={styles.popupBtn}
                        onClick={openDeleteDg}>
                        <i><DeleteIcon /></i>
                        Delete
                    </button>
                </div>
            </Popover>
            <Dialog
                open={opendelete}
                onClose={() => setDelete(false)}
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%",
                            maxWidth: "500px",
                        },
                    },
                }}
            >
                <Typography
                    variant='h4'
                    sx={{ textAlign: 'center', padding: '1rem' }}>
                    Delete Leave
                </Typography>
                <DialogContent>
                    <Typography
                        variant='body1'
                        sx={{ textAlign: 'center', paddingTop: '0%' }}>
                        Are you sure want to delete?
                    </Typography>
                </DialogContent>
                <div
                    style={{ display: 'flex', justifyContent: 'space-around' }}>
                    <button
                        className={styles.dialogbtn}
                        onClick={() => (delateData(rows.id))} >
                        Delete
                    </button>
                    <button
                        className={styles.dialogbtn}
                        onClick={() => setDelete(false)}>
                        Cancle
                    </button>
                </div>
            </Dialog>
            <CustomDialog openAdd={openAdd} handleClose={handleClose} />
        </>
    );
}

