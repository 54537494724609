import { useEffect, useState } from "react";
import { sidebarData } from "../../data/SidebarData/SidebarData";
import styles from "./Sidebar.module.scss";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Collapse,
  Link,
  List,
  ListItem,
  ListItemIcon,
} from "@mui/material";

interface SidebarProps {
  open: boolean;
}

const Sidebar = ({ open }: SidebarProps) => {
  const [expandedSubMenu, setExpandedSubMenu] = useState<string | null>(null);
  const [expandedSubMenuItem, setExpandedSubMenuItem] = useState<string | null>(
    null
  );

  useEffect(() => {
    if (open === true) {
      setExpandedSubMenu(null);
      setExpandedSubMenuItem(null);
    }
  }, [open]);
  const toggleSubMenu = (key: string) => {
    setExpandedSubMenu(expandedSubMenu === key ? null : key);
  };

  const toggleSubSubMenu = (key: string) => {
    setExpandedSubMenuItem(expandedSubMenuItem === key ? null : key);
  };

  return (
    <>
      <Box
        className={`${styles.sidebar} ${open ? styles.showIcon : ""} ${
          open ? styles.open : ""
        }`}
      >
        {Object.keys(sidebarData).map((section) => (
          <Box key={section}>
            <ListItem
              className={`${styles.main_title} ${open ? styles.showIcon : ""} `}
            >
              {section}
            </ListItem>
            {sidebarData[section].map((item: any) => (
              <Box key={item.title}>
                <ListItem
                  onClick={() =>
                    item.subItems &&
                    item.subItems.length > 0 &&
                    toggleSubMenu(item.title)
                  }
                >
                  <Link className={styles.subTitleLink} underline="none">
                    <ListItemIcon
                      sx={{ minWidth: 40, color: "grey" }}
                      className={styles.menu_icon}
                    >
                      <item.icon />
                    </ListItemIcon>
                    <span
                      className={`${styles.subTitle} 
                                        ${
                                          item.title === "Employees"
                                            ? styles.employeeDot
                                            : ""
                                        } `}
                    >
                      {item.title}
                    </span>
                    {item.subItems &&
                      item.subItems.length > 0 &&
                      (expandedSubMenu === item.title ? (
                        <ExpandMoreIcon className={styles.expand_icon} />
                      ) : (
                        <KeyboardArrowRightIcon
                          className={styles.expand_icon}
                        />
                      ))}
                  </Link>
                </ListItem>
                {item.subItems && item.subItems.length > 0 && (
                  <Collapse
                    in={expandedSubMenu === item.title}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List className={styles.subMenuItemList}>
                      {item.subItems.map((subItem: any) => (
                        <ListItem sx={{ display: "block" }} key={subItem.key}>
                          <Box onClick={() => toggleSubSubMenu(subItem.key)}>
                            <Link href={subItem?.nav} className={styles.links}>
                              {subItem.name}
                            </Link>
                            {subItem.submenu &&
                              subItem.submenu.length > 0 &&
                              (expandedSubMenuItem === subItem.key ? (
                                <ExpandMoreIcon
                                  className={styles.expand_icon}
                                />
                              ) : (
                                <KeyboardArrowRightIcon
                                  className={styles.expand_icon}
                                />
                              ))}
                          </Box>

                          <Collapse
                            in={expandedSubMenuItem === subItem.key}
                            timeout="auto"
                            unmountOnExit
                          >
                            {subItem.submenu && (
                              <>
                                {subItem.submenu.map((submenuItem: any) => (
                                  <ListItem
                                    key={submenuItem.key}
                                    sx={{ display: "block", paddingLeft: 4 }}
                                  >
                                    <Link
                                      href="#"
                                      className={styles.links}
                                      key={submenuItem.key}
                                    >
                                      {submenuItem.name}
                                    </Link>
                                  </ListItem>
                                ))}
                              </>
                            )}
                          </Collapse>
                        </ListItem>
                      ))}
                    </List>
                  </Collapse>
                )}
              </Box>
            ))}
          </Box>
        ))}
      </Box>
    </>
  );
};

export default Sidebar;
