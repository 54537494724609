import {
    Box, Divider, FormControl, InputLabel, MenuItem, Paper,
    Select, SelectChangeEvent, Table, TableCell, TableRow,
    TableContainer, TableHead, Typography, TableBody,
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import DateRangeIcon from '@mui/icons-material/DateRange';
import SettingsOverscanIcon from '@mui/icons-material/SettingsOverscan';
import styles from './DealDashboard.module.scss'
import { BarChart } from '@mui/x-charts/BarChart';
import React, { useState } from 'react';
import { LineChart } from '@mui/x-charts';
import DealCalender from './DealCalender';


const pData = [400, 300, 200, 278, 189, 239];
const xLabelsMarket = [
    'Inpipeline',
    'Follow Up',
    'Schedule',
    'Won',
    'Conversation',
    'Lost',
];
const yLabelsLost = [
    'Conversation',
    'Inpipeline',
    'Follow Up',
];
const lostData = [300, 200, 278]
const marketingItem = ['Marketing Pipeline', 'Sales Pipeline', 'Email', 'Chats', 'Operational']
const monthItem = ['last 3 Months', 'last 6 Months', 'last 13 Months',]
// tempart table data
function createData(
    name: string,
    calories: number,
    fat: number,
    carbs: number,
    Status: String,
) {
    return { name, calories, fat, carbs, Status };
}

const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, "Open"),
    createData('Ice cream sandwich', 237, 9.0, 37, 'Lost'),
    createData('Eclair', 262, 16.0, 24, 'Won'),
    createData('Cupcake', 305, 3.7, 67, 'Lost'),
    createData('Gingerbread', 356, 16.0, 49, 'Lost'),
];

const DealDashboard: React.FC<{ open: boolean }> = ({ open }) => {
    const [selectDashboard, setselectDashboard] = useState<string>('')
    const [selectMarketing, setselectMarketing] = useState<string>('Marketing Pipeline')
    const [selectMonth, setselectMonth] = useState<string>('last 3 Months')


    const handleChange = (event: SelectChangeEvent) => {
        setselectDashboard(event.target.value as string);
    };
    const changeMarketing = (event: SelectChangeEvent) => {
        setselectMarketing(event.target.value as string);
    };
    const changeMonth = (event: SelectChangeEvent) => {
        setselectMonth(event.target.value as string);
    };

    const SelectMarkerting = () => {
        return (
            <FormControl sx={{ m: .1, minWidth: 100 }} size="small">
                <Select
                    sx={{ fontSize: '.8rem' }}
                    value={selectMarketing}
                    onChange={changeMarketing}
                >
                    {marketingItem.map((item: string, index: number) => (
                        <MenuItem
                            value={item}
                            key={index}
                            sx={{ fontSize: '.8rem' }}>
                            {item}
                        </MenuItem>
                    ))}

                </Select>
            </FormControl>

        )
    }
    const SelectMonth = () => {
        return (
            <FormControl
                sx={{ m: .1, minWidth: 100 }} size="small">
                <Select
                    sx={{ fontSize: '.8rem' }}
                    value={selectMonth}
                    onChange={changeMonth}
                >
                    {monthItem.map((item, index) => (
                        <MenuItem
                            value={item}
                            key={index}
                            sx={{ fontSize: '.8rem' }}>
                            {item}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        )
    }

    return (
        <>
            <Box className={` ${open ? styles.open : styles.dealMain_Container}`}>
                <div className={styles.dealSub_Container}>
                    <Box className={styles.dealHeader}>
                        <div>
                            <Typography
                                sx={{ fontWeight: 700 }}
                                variant='h5'>Deal Dashboard
                            </Typography>
                        </div>
                        <div className={styles.dealHeader_input} >
                            <div className={styles.refreshIcon}>
                                <i className={styles.viewIcon}>
                                    <RefreshIcon />
                                </i>
                                <i className={`${styles.viewIcon} 
                                ${styles.fullscreenIcon}`}>
                                    <SettingsOverscanIcon />
                                </i>
                            </div>
                            <div className={styles.export}>
                                <i>
                                    <ExitToAppIcon fontSize='small' />
                                </i>
                                <p>Export</p>
                            </div>
                            <div className={styles.date_select}>
                                <Box className={styles.date_range}>
                                    <i><DateRangeIcon /></i>
                                    <DealCalender />
                                </Box>
                                <div className={styles.selectDashboard}>
                                    <FormControl sx={{ m: 1, minWidth: 200 }}>
                                        <InputLabel>Select Dashboard</InputLabel>
                                        <Select
                                            value={selectDashboard}
                                            label='select dashboard'
                                            onChange={handleChange}
                                        >
                                            <MenuItem value='Deal Dashboard'>Deal Dashboard</MenuItem>
                                            <MenuItem value='Leal Dashboard'>Leal Dashboard</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                    </Box>
                    <Divider />
                    <Box >
                        <Box className={styles.dealMainContent}>
                            <Paper
                                className={styles.dealStages}
                                elevation={4}>
                                <div className={styles.dealConent}>
                                    <Typography
                                        variant='h5'
                                        className={styles.allDealHeader}>Deal By Stage
                                    </Typography>
                                    <div className={styles.paperSelect}>
                                        <div className={styles.selectMarginAll}>
                                            <SelectMarkerting />
                                        </div>
                                        <div><SelectMonth /> </div>
                                    </div>
                                </div>
                                <Box
                                    className={`${styles.barGraph} 
                                        ${styles.dealByStageGraph}`}>
                                    <BarChart
                                        grid={{ horizontal: true }}
                                        barLabel="value"
                                        series={[
                                            { data: pData, id: 'pvId', color: 'rgba(255, 195, 143, 0.85)' },
                                        ]}
                                        xAxis={[{ data: xLabelsMarket, scaleType: 'band' }]}
                                    />
                                </Box>
                            </Paper >
                            <Paper
                                className={styles.dealStages}
                                elevation={4}>
                                <div className={styles.dealConent}>
                                    <Typography
                                        variant='h6'
                                        className={styles.allDealHeader}>Recenty Creted Contacts
                                    </Typography>
                                    <div><SelectMonth /></div>
                                </div>
                                <Box >
                                    <TableContainer component={Paper}>
                                        <Table
                                            sx={{ minWidth: 300 }}
                                            aria-label="simple table">
                                            <TableHead>
                                                <TableRow >
                                                    <TableCell>Dessert (100g serving)</TableCell>
                                                    <TableCell align="right">Calories</TableCell>
                                                    <TableCell align="right">Fat</TableCell>
                                                    <TableCell align="right">Carbs</TableCell>
                                                    <TableCell align="right">Status</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {rows.map((row) => (
                                                    <TableRow
                                                        key={row.name}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row">
                                                            {row.name}
                                                        </TableCell>
                                                        <TableCell align="right">{row.calories}</TableCell>
                                                        <TableCell align="right">{row.fat}</TableCell>
                                                        <TableCell align="right">{row.carbs}</TableCell>
                                                        <TableCell align="right"
                                                            className=
                                                            {`${row.Status === 'Open' ? styles.openstate : ''} 
                                                              ${row.Status === 'Lost' ? styles.lost : ''}
                                                              ${row.Status === 'Won' ? styles.won : ''}`
                                                            }>{row.Status}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                            </Paper>
                            <Paper
                                className={styles.dealStages}
                                elevation={4}>
                                <div className={styles.dealConent}>
                                    <Typography
                                        variant='h6'
                                        className={styles.allDealHeader}>Lost Deal Stages
                                    </Typography>
                                    <div className={styles.paperSelect}>
                                        <div className={styles.selectMarginAll}><SelectMarkerting /></div>
                                        <div><SelectMonth /> </div>
                                    </div>
                                </div>
                                <Box className={styles.barGraph}>
                                    <BarChart
                                        margin={{ left: 90 }}
                                        grid={{ horizontal: true }}
                                        series={[
                                            { data: lostData, color: 'rgba(249, 108, 133, 0.85)' },
                                        ]}
                                        yAxis={[{ data: yLabelsLost, scaleType: 'band' }]}
                                        layout="horizontal"
                                    />
                                </Box>
                            </Paper>
                            <Paper
                                className={styles.dealStages}
                                elevation={4}>
                                <div className={styles.dealConent}>
                                    <Typography
                                        variant='h6'
                                        className={styles.allDealHeader}>Won Deal Stages
                                    </Typography>
                                    <div className={styles.paperSelect}>
                                        <div className={styles.selectMarginAll}>
                                            <SelectMarkerting />
                                        </div>
                                        <div><SelectMonth /> </div>
                                    </div>
                                </div>
                                <Box className={styles.barGraph}>
                                    <BarChart
                                        margin={{ left: 90 }}
                                        grid={{ horizontal: true }}
                                        series={[
                                            { data: lostData, color: 'rgba(119, 216, 130, 0.85)' },
                                        ]}
                                        yAxis={[{ data: yLabelsLost, scaleType: 'band' }]}
                                        layout="horizontal"
                                    />
                                </Box>
                            </Paper>
                        </Box>
                        <Paper
                            className={`${styles.dealStages} 
                         ${styles.dealByStageGraph}`}
                            elevation={4}>
                            <div className={styles.dealConent}>
                                <Typography
                                    variant='h6'
                                    className={styles.allDealHeader}>Deal By Year
                                </Typography>
                                <div><SelectMonth /></div>
                            </div>
                            <Box className={styles.barGraph}>
                                <LineChart
                                    height={300}
                                    xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}
                                    series={[
                                        {
                                            data: [2, 5.5, 2, 8.5, 1.5, 5],
                                        },
                                    ]}
                                />
                            </Box>
                        </Paper>
                    </Box>
                </div>
            </Box >
        </>
    )
}

export default DealDashboard