import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Dashboard } from "../Dashboard/Dashboard";
import Protected from "./Protected";
import { Login } from "../Login/Login";
import { RouteErrorPage } from "../../Common/RouteErrorPage/RouteErrorPage";
import Admin from "../Dashboard/Admin dashboard/Admin";
import DealDashboard from "../Dashboard/DealDashboard/DealDashboard";
import Sidebar from "../Sidebar/Sidebar";
import Employee from "../Dashboard/Employee dashboard/Employee";
import { useState } from "react";
import Header from "../Header/Header";
import AdminLeave from "../Employees/AdminLeave/AdminLeave";
import Employees from "../Employees/All Employees/Employees";

// import AdminAttendance from "../Dashboard/Admin Attendance/AdminAttendance";
import LeaveEmployee from "../Dashboard/LeaveEmployee/LeaveEmployee";
import Overtime from "../Dashboard/Overtime/Overtime";

import AdminAttendance from "../Employees/Admin Attendance/AdminAttendance";
import Departments from "../Employees/Departments/Departments";
import Designation from "../Employees/Designations/Designations";
import Timesheet from "../Employees/Timesheet/Timesheet";
import ShiftShedule from "../Employees/ShiftAndSchedule/ShiftShedule/ShiftShedule";
import ShiftList from "../Employees/ShiftAndSchedule/ShiftList/ShiftList";

const AppRoutes = () => {
  const [open, setOpen] = useState<boolean>(false);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const isLoginPage = () => window.location.pathname === "/";

  return (
    <>
      <div style={{ paddingTop: isLoginPage() ? 0 : 50 }}>
        {!isLoginPage() && <Header toggleDrawer={toggleDrawer} />}
        {!isLoginPage() && <Sidebar open={open} />}
        <Router>
          <Routes>
            <Route path="*" element={<RouteErrorPage />} />
            <Route path="/" Component={() => <Login />} />
            <Route path="/admin-dashboard" element={<Admin open={open} />} />
            <Route
              path="/deal-dashboard"
              element={<DealDashboard open={open} />}
            />
            <Route
              path="/employee-dashboard"
              element={<Employee open={open} />}
            />
            <Route path="/all-employees" element={<Employees open={open} />} />
            <Route path="/adminleaves" element={<AdminLeave open={open} />} />
            <Route
              path="/leaveemployee"
              element={<LeaveEmployee open={open} />}
            />
            <Route
              path="/adminattendance"
              element={<AdminAttendance open={open} />}
            />

            <Route path="/overtime" element={<Overtime open={open} />} />

            <Route path="/departments" element={<Departments open={open} />} />
            <Route path="/designations" element={<Designation open={open} />} />
            <Route path="/timesheet" element={<Timesheet open={open} />} />
            <Route
              path="/shift-scheduling"
              element={<ShiftShedule open={open} />}
            />
            <Route path="/shift-list" element={<ShiftList open={open} />} />

            <Route
              path="/dashboard"
              Component={() => (
                // <Protected>
                <Dashboard />
                // </Protected>
              )}
            />
          </Routes>
        </Router>
      </div>
    </>
  );
};

export default AppRoutes;
