import DashboardRoundedIcon from "@mui/icons-material/DashboardRounded";
import ViewInArRoundedIcon from "@mui/icons-material/ViewInArRounded";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import GroupsIcon from "@mui/icons-material/Groups";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import ConfirmationNumberOutlinedIcon from "@mui/icons-material/ConfirmationNumberOutlined";
import BusinessRoundedIcon from "@mui/icons-material/BusinessRounded";
import ContactPageOutlinedIcon from "@mui/icons-material/ContactPageOutlined";
import KeyIcon from "@mui/icons-material/Key";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import UpdateSharpIcon from "@mui/icons-material/UpdateSharp";
import AutoStoriesRoundedIcon from "@mui/icons-material/AutoStoriesRounded";
import LeaderboardRoundedIcon from "@mui/icons-material/LeaderboardRounded";
import SyncAltRoundedIcon from "@mui/icons-material/SyncAltRounded";
import AnalyticsOutlinedIcon from "@mui/icons-material/AnalyticsOutlined";
import FilterNoneIcon from "@mui/icons-material/FilterNone";
import PaymentIcon from "@mui/icons-material/Payment";
import PolicyIcon from "@mui/icons-material/Policy";
import PieChartOutlinedIcon from "@mui/icons-material/PieChartOutlined";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import NoteAltOutlinedIcon from "@mui/icons-material/NoteAltOutlined";
import CampaignIcon from "@mui/icons-material/Campaign";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import QuestionMarkOutlinedIcon from "@mui/icons-material/QuestionMarkOutlined";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import AccountBoxOutlinedIcon from "@mui/icons-material/AccountBoxOutlined";
import KeyboardCapslockOutlinedIcon from "@mui/icons-material/KeyboardCapslockOutlined";
import SsidChartOutlinedIcon from "@mui/icons-material/SsidChartOutlined";
import AppsOutlinedIcon from "@mui/icons-material/AppsOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import GpsNotFixedOutlinedIcon from "@mui/icons-material/GpsNotFixedOutlined";
import AddTaskIcon from "@mui/icons-material/AddTask";
import GridOnOutlinedIcon from "@mui/icons-material/GridOnOutlined";
import ViewInArIcon from "@mui/icons-material/ViewInAr";
import CameraRearOutlinedIcon from "@mui/icons-material/CameraRearOutlined";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import React from "react";

type SubItem = {
  name: string;
  key: string;
  submenu?: SubItem[];
  nav?: string;
};
type SidebarItem = {
  icon: React.ComponentType;
  title: string;
  subItems: SubItem[];
};
type SidebarData = {
  [key: string]: SidebarItem[]; // Add index signature to allow indexing by string
};

export const sidebarData: SidebarData = {
  main: [
    {
      icon: DashboardRoundedIcon, // Replace with the actual icon reference
      title: "Dashboard",
      subItems: [
        {
          name: "Admin Dashboard",
          key: "adminDashboard",
          nav: "/admin-dashboard",
        },
        {
          name: "Employee Dashboard",
          key: "employeeDashboard",
          nav: "/employee-dashboard",
        },
        {
          name: "Deals Dashboard",
          key: "dealsDashboard",
          nav: "/deal-dashboard",
        },
        { name: "Leads", key: "leads" },
      ],
    },
    {
      icon: ViewInArRoundedIcon,
      title: "Apps",
      subItems: [
        { name: "Chats", key: "chats" },
        {
          name: "Calls",
          key: "calls",
          submenu: [
            { name: "Voice Call", key: "voiceCall" },
            { name: "Video Call", key: "videoCall" },
            { name: "Outgoing Call", key: "outgoingCall" },
            { name: "Incoming Call", key: "incomingCall" },
          ],
        },
        { name: "Calendar", key: "calendar" },
        { name: "Contacts", key: "contacts" },
        { name: "Email", key: "email" },
        { name: "File Manager", key: "fileManager" },
      ],
    },
  ],
  employees: [
    {
      icon: PersonOutlineOutlinedIcon,
      title: "Employees",
      subItems: [
        { name: "All Employees", key: "allEmployees", nav: "/all-employees" },
        { name: "Holidays", key: "holidays", nav: "/holidays" },
        { name: "Leaves (Admin)", key: "leavesAdmin" },
        {
          name: "Leaves (Employee)",
          key: "leavesEmployee",
          nav: "/leaveemployee",
        },
        { name: "Leave Settings", key: "leaveSettings" },
        {
          name: "Attendance (Admin)",
          key: "attendanceAdmin",
          nav: "/adminattendance",
        },
        { name: "Attendance (Employee)", key: "attendanceEmployee" },
        { name: "Departments", key: "departments", nav: "/departments" },
        { name: "Designations", key: "designations", nav: "/designations" },
        { name: "Timesheet", key: "timesheet", nav: "/timesheet" },
        {
          name: "Shift & Schedule",
          key: "shiftSchedule",
          nav: "/shift-scheduling",
        },
        { name: "Overtime", key: "overtime", nav: "/overtime" },
      ],
    },
    {
      icon: GroupsIcon,
      title: "Client",
      subItems: [],
    },
    {
      icon: RocketLaunchIcon,
      title: "project",
      subItems: [
        { name: "project", key: "project" },
        { name: "Task", key: "Task" },
        { name: "Task Board", key: "TaskBoard" },
      ],
    },
    {
      icon: ConfirmationNumberOutlinedIcon,
      title: "Tickets",
      subItems: [
        { name: "Tickets", key: "Tickets" },
        { name: "Tickets Detail", key: "Tickets Detail" },
      ],
    },
  ],
  Crm: [
    {
      icon: ContactPageOutlinedIcon,
      title: "contact",
      subItems: [],
    },
    {
      icon: BusinessRoundedIcon,
      title: "Companies",
      subItems: [],
    },
    {
      icon: ViewInArIcon,
      title: "Deals",
      subItems: [],
    },
    {
      icon: LeaderboardRoundedIcon,
      title: "Lead",
      subItems: [],
    },
    {
      icon: SyncAltRoundedIcon,
      title: "Pipeline",
      subItems: [],
    },
    {
      icon: AnalyticsOutlinedIcon,
      title: "Analytics",
      subItems: [],
    },
    {
      icon: AddTaskIcon,
      title: "Activity",
      subItems: [],
    },
  ],
  Hr: [
    {
      icon: FilterNoneIcon,
      title: "Sales",
      subItems: [
        { name: "Estimates", key: "estimates" },
        { name: "Invoices", key: "invoices" },
        { name: "Payments", key: "payments" },
        { name: "Expenses", key: "expenses" },
        { name: "Provident Fund", key: "providentFund" },
        { name: "Taxes", key: "taxes" },
      ],
    },
    {
      icon: FilterNoneIcon,
      title: "Accounting",
      subItems: [
        { name: "Categories", key: "estimates" },
        { name: "Budgets", key: "invoices" },
        { name: "Budget Revenue", key: "payments" },
        { name: "Budget Expenses", key: "expenses" },
      ],
    },
    {
      icon: PaymentIcon,
      title: "Payroll",
      subItems: [
        { name: "Employee Salary", key: "Employee Salary" },
        { name: "Payslip", key: "Payslip" },
        { name: "Payroll  Item", key: "Payroll  Item" },
      ],
    },
    {
      icon: PolicyIcon,
      title: "Polycies",
      subItems: [],
    },
    {
      icon: PieChartOutlinedIcon,
      title: "Report",
      subItems: [
        { name: "Expense Report", key: "expenseReport" },
        { name: "Invoice Report", key: "invoiceReport" },
        { name: "Payments Report", key: "paymentsReport" },
        { name: "Project Report", key: "projectReport" },
        { name: "Task Report", key: "taskReport" },
        { name: "User Report", key: "userReport" },
        { name: "Employee Report", key: "employeeReport" },
        { name: "Payslip Report", key: "payslipReport" },
        { name: "Attendance Report", key: "attendanceReport" },
        { name: "Leave Report", key: "leaveReport" },
        { name: "Daily Report", key: "dailyReport" },
      ],
    },
  ],
  Performance: [
    {
      icon: SchoolOutlinedIcon,
      title: "performance",
      subItems: [
        { name: "performance indicator", key: "performance indicator" },
        { name: "performance review", key: "performance review" },
        { name: "performance appraisal", key: "performance appraisal" },
      ],
    },
    {
      icon: GpsNotFixedOutlinedIcon,
      title: "Goal",
      subItems: [
        { name: "Goal List", key: "Goal List" },
        { name: "Goal type", key: "Goal type" },
      ],
    },
    {
      icon: NoteAltOutlinedIcon,
      title: "Training",
      subItems: [
        { name: "Training List", key: "Training List" },
        { name: "Trainers", key: "Trainers" },
        { name: "Training type", key: "Training type" },
      ],
    },
    {
      icon: CampaignIcon,
      title: "Promotion",
      subItems: [],
    },
    {
      icon: CancelPresentationIcon,
      title: "Resignation",
      subItems: [],
    },
    {
      icon: HighlightOffIcon,
      title: "Termination",
      subItems: [],
    },
  ],
  Administation: [
    {
      icon: AccountTreeOutlinedIcon,
      title: "Assets",
      subItems: [],
    },
    {
      icon: WorkOutlineOutlinedIcon,
      title: "Job",
      subItems: [
        { name: "User Dashboard", key: "userDashboard" },
        { name: "Jobs Dashboard", key: "jobsDashboard" },
        { name: "Manage Jobs", key: "manageJobs" },
        { name: "Manage Resumes", key: "manageResumes" },
        { name: "Shortlist Candidates", key: "shortlistCandidates" },
        { name: "Interview Questions", key: "interviewQuestions" },
        { name: "Offer Approvals", key: "offerApprovals" },
        { name: "Experience Level", key: "experienceLevel" },
        { name: "Candidates List", key: "candidatesList" },
        { name: "Schedule Timing", key: "scheduleTiming" },
        { name: "Aptitude Results", key: "aptitudeResults" },
      ],
    },
    {
      icon: QuestionMarkOutlinedIcon,
      title: "Knowledgebase",
      subItems: [],
    },
    {
      icon: PersonOutlinedIcon,
      title: "User",
      subItems: [],
    },
    {
      icon: SettingsOutlinedIcon,
      title: "Setting",
      subItems: [],
    },
  ],
  Pages: [
    {
      icon: AccountBoxOutlinedIcon,
      title: "Profile",
      subItems: [
        { name: "Employee Profile", key: "Employee Profile" },
        { name: "Clint Profile", key: "Clint Profile" },
      ],
    },
    {
      icon: KeyIcon,
      title: "Authentication",
      subItems: [
        { name: "Login", key: "Login" },
        { name: "Register", key: "Clint Profile" },
        { name: "Forgot Password", key: "Forgot Password" },
        { name: "OTP", key: "OTP" },
        { name: "Lock Screen", key: "Lock Screen" },
      ],
    },
    {
      icon: WarningAmberIcon,
      title: "Error Page",
      subItems: [
        { name: "404 Error", key: "404 Error" },
        { name: "500 Error", key: "500 Error" },
      ],
    },
    {
      icon: UpdateSharpIcon,
      title: "Subscription",
      subItems: [
        { name: "Subscription (Admin)", key: "Subscription (Admin)" },
        { name: "Subscription (Company)", key: "Subscription (Company)" },
        { name: "Subscription companies", key: "Subscription companies" },
      ],
    },
    {
      icon: AutoStoriesRoundedIcon,
      title: "Pages",
      subItems: [
        { name: "Search", key: "search" },
        { name: "FAQ", key: "faq" },
        { name: "Terms", key: "terms" },
        { name: "Privacy Policy", key: "privacyPolicy" },
        { name: "Blank Page", key: "blankPage" },
        { name: "Coming Soon", key: "comingSoon" },
        { name: "Under Maintenance", key: "underMaintenance" },
      ],
    },
  ],
  UiInterface: [
    {
      icon: CameraRearOutlinedIcon,
      title: "Base Ui",
      subItems: [
        { name: "Alerts", key: "alerts" },
        { name: "Accordion", key: "accordion" },
        { name: "Avatar", key: "avatar" },
        { name: "Badges", key: "badges" },
        { name: "Border", key: "border" },
        { name: "Buttons", key: "buttons" },
        { name: "Button Group", key: "buttonGroup" },
        { name: "Breadcrumb", key: "breadcrumb" },
        { name: "Card", key: "card" },
        { name: "Carousel", key: "carousel" },
        { name: "Colors", key: "colors" },
        { name: "Dropdowns", key: "dropdowns" },
        { name: "Grid", key: "grid" },
        { name: "Images", key: "images" },
        { name: "Lightbox", key: "lightbox" },
        { name: "Media", key: "media" },
        { name: "Modals", key: "modals" },
        { name: "Notification", key: "notification" },
        { name: "Offcanvas", key: "offcanvas" },
        { name: "Pagination", key: "pagination" },
        { name: "Popovers", key: "popovers" },
        { name: "Progress", key: "progress" },
        { name: "Placeholders", key: "placeholders" },
        { name: "Range Slider", key: "rangeSlider" },
        { name: "Spinner", key: "spinner" },
        { name: "Sweet Alerts", key: "sweetAlerts" },
        { name: "Tabs", key: "tabs" },
        { name: "Toasts", key: "toasts" },
        { name: "Tooltips", key: "tooltips" },
        { name: "Typography", key: "typography" },
        { name: "Video", key: "video" },
      ],
    },
    {
      icon: KeyboardCapslockOutlinedIcon,
      title: "Advance Ui",
      subItems: [
        { name: "Ribbon", key: "ribbon" },
        { name: "Clipboard", key: "clipboard" },
        { name: "Drag & Drop", key: "dragDrop" },
        { name: "Range Slider", key: "rangeSlider" },
        { name: "Rating", key: "rating" },
        { name: "Text Editor", key: "textEditor" },
        { name: "Counter", key: "counter" },
        { name: "Scrollbar", key: "scrollbar" },
        { name: "Sticky Note", key: "stickyNote" },
        { name: "Timeline", key: "timeline" },
      ],
    },
    {
      icon: SsidChartOutlinedIcon,
      title: "Charts",
      subItems: [
        { name: "Apex Charts", key: "apexCharts" },
        { name: "Chart.js", key: "chartJs" },
        { name: "Morris Charts", key: "morrisCharts" },
        { name: "Flot Charts", key: "flotCharts" },
        { name: "Peity Charts", key: "peityCharts" },
        { name: "C3 Charts", key: "c3Charts" },
      ],
    },
    {
      icon: AppsOutlinedIcon,
      title: "Icons",
      subItems: [
        { name: "Fontawesome Icons", key: "Fontawesome Icons" },
        { name: "Feather Icons", key: "Feather Icons" },
        { name: "Ionic Icons", key: "Ionic Icons" },
        { name: "Material Icons", key: "Material Icons" },
        { name: "Pe7 Icons", key: "Pe7 Icons" },
        { name: "Simpleline Icons", key: "Simpleline Icons" },
        { name: "Themify Icons", key: "Themify Icons" },
        { name: "Weather Icons", key: "Weather Icons" },
        { name: "Typicon Icons", key: "Typicon Icons" },
        { name: "Flag Icons", key: "Flag Icons" },
      ],
    },
    {
      icon: ListAltOutlinedIcon,
      title: "Forms",
      subItems: [
        { name: "Basic Inputs", key: "Basic Inputs" },
        { name: "Input Groups", key: "Input Groups" },
        { name: "Horizontal Form", key: "Horizontal Form" },
        { name: "Vertical Form", key: "Vertical Form" },
        { name: "Form Mask", key: "Form Mask" },
        { name: "Form Validation", key: "Form Validation" },
        { name: "Form Select2", key: "Form Select2" },
        { name: "File Upload", key: "File Upload" },
        { name: "Horizontal Timeline", key: "Horizontal Timeline" },
        { name: "Form Wizard", key: "Form Wizard" },
      ],
    },

    {
      icon: GridOnOutlinedIcon,
      title: "tables",
      subItems: [
        { name: "Basic tables", key: "Basic tables" },
        { name: "Data tables", key: "Data tables" },
      ],
    },
  ],
  Extras: [
    {
      icon: DescriptionOutlinedIcon,
      title: "Documantation",
      subItems: [],
    },
  ],
};
