import React from 'react'
import styles from "./LeaveEmployee.module.scss";
import { useNavigate } from 'react-router-dom';
//import Button from '@mui/material/Button';
import { Label } from '@mui/icons-material';
//import Box from '@mui/material/Box';
//import FormControl from '@mui/material/FormControl';
//import Select, { SelectChangeEvent } from '@mui/material/Select';
//import InputLabel from '@mui/material/InputLabel';
//import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import LeaveTable from './LeaveTable';
import {
  Box, Button, Dialog, DialogContent,
  FormControl, InputLabel, MenuItem, Select,
  SelectChangeEvent, TextField, TextareaAutosize, Typography
} from "@mui/material"
//import CloseIcon from '@mui/icons-material/Close';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CloseIcon from '@mui/icons-material/Close';
import DateRangeIcon from '@mui/icons-material/DateRange';

//import EnhancedTable from "./AdminLeaveTable";

interface deaprops {
    open: boolean
}

const LeaveEmployee:React.FC<deaprops> = ({ open }) => {
    const navigate = useNavigate();
    const [age, setAge] = React.useState('');


    const handleChange = (event: SelectChangeEvent) => {
        setAge(event.target.value as string);
      };

    function navi()
    {
        navigate("Admin-dashboard")
    }

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.common.white,
          color: theme.palette.common.black,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
      }));
      
      const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
          border: 0,
        },
      }));
      
      function createData(
        name: string,
        calories: string,
        fat: string,
        carbs: string,
        protein: string,
        status:String,
        action:string
      ) {
        return { name, calories, fat, carbs, protein,status,action };
      }
      
      const rows = [
        createData('LOP','24 Feb 2023','25 Feb 2023','2 days','personal','approved','...'),
        // createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
        // createData('Eclair', 262, 16.0, 24, 6.0),
        // createData('Cupcake', 305, 3.7, 67, 4.3),
        // createData('Gingerbread', 356, 16.0, 49, 3.9),
      ];
      const options = [
        'None',
        'Atria',
        'Callisto',]


  return (
    <>
    <div className={` ${open ? styles.open : styles.leaveempMain_Container}`}>
        <div className={styles.leavediv}>
            <div className={styles.innerleavediv}>
                <span className={styles.leavetxt}>Leaves</span><br></br>
                <p onClick={navi} style={{cursor:"pointer"}}>Dashboard / Leaves</p>
            </div>
            <div className={styles.addbtn}>
                <Button variant="contained" size="medium" 
                    sx={{backgroundColor:"darkorange",
                    borderRadius:'20px',
                    width:'150px',
                    fontWeight:600}}>
                    + Add New
                </Button>
            </div>
        </div>
                    {/* Row Section */}
                    <div className={styles.row}>
                        <div className={styles.statsinfo}>
                            <h6 style={{fontWeight:450}}>Annual Leave</h6>
                            <h4 style={{fontWeight:650}}>12</h4>
                        </div>
                        <div className={styles.statsinfo}>
                            <h6 style={{fontWeight:450}}>Medical Leave</h6>
                            <h4 style={{fontWeight:650}}>3</h4>
                        </div>
                        <div className={styles.statsinfo}>
                            <h6 style={{fontWeight:450}}>Other Leave</h6>
                            <h4 style={{fontWeight:650}}>4</h4>
                        </div>
                        <div className={styles.statsinfo}>
                            <h6 style={{fontWeight:450}}>Remaining Leave</h6>
                            <h4 style={{fontWeight:650}}>5</h4>
                        </div>


                    </div>

                            {/* show entries */}
                                <LeaveTable/>

    </div>

    </>
  )
}

export default LeaveEmployee

interface CustomDialogProps {
  openAdd: boolean,
  handleClose: () => void,
}
const CustomDialog = ({ openAdd, handleClose }: CustomDialogProps) => {
  return (
    <>
      <Dialog
        open={openAdd}
        onClose={handleClose}
        sx={{
          "& .MuiDialog-container": {

            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "500px",
            },
          },
        }}
      >
        <DialogContent
          sx={{
            '&.MuiDialogContent-root': {
              overflowX: 'hidden'
            }
          }}>
          <FormControl sx={{
            '&.MuiFormControl-root': {
              width: "100%",
            }
          }}>
            <Box
              className={styles.dialogHeader}>
              <Typography
                variant="h5"
                sx={{ fontWeight: 500, margin: '.5rem' }}>
                Add Leaves
              </Typography>
              <i onClick={handleClose}>
                <CloseIcon />
              </i>
            </Box>
            <Typography
              variant="body1"
              sx={{ marginTop: '.5rem' }}>
              Leave Type
              <span>*</span>
            </Typography>

            <TextField required />

            <Typography
              variant="body1"
              sx={{ marginTop: '.5rem' }}>
              From
              <span>*</span>
            </Typography>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}>
              <DatePicker
                label="From"
              />
              {/* <i className={styles.dateicondg} ><DateRangeIcon /></i> */}
            </LocalizationProvider>

            <Typography
              variant="body1"
              sx={{ marginTop: '.5rem' }}>
              To
              <span>*</span>
            </Typography >
            <LocalizationProvider
              dateAdapter={AdapterDayjs}>
              <DatePicker
                label="From"
              />
              {/* <i className={styles.dateicondg}><DateRangeIcon /></i> */}
            </LocalizationProvider>

            <Typography
              variant="body1"
              sx={{ marginTop: '.5rem' }}>
              Number of Day
              <span>*</span>
            </Typography>
            <TextField disabled sx={{ backgroundColor: 'lightgray' }} />

            <Typography
              variant="body1"
              sx={{ marginTop: '.5rem' }}>
              Ramaining Leaves
              <span>*</span>
            </Typography>

            <TextField disabled sx={{ backgroundColor: 'lightgray' }} />

            <Typography
              variant="body1"
              sx={{ marginTop: '.5rem' }}>
              Leave Reason
              <span>*</span>
            </Typography>
            <TextareaAutosize minRows={8} />

            <Button variant='text'
              sx={{
                margin: '1.5rem',
                borderRadius: '1rem',
                color: 'white',
                backgroundColor: '#fb8c00',
                '&:hover': {
                  backgroundColor: '#fb8c00',
                },
              }}
            >Submit</Button>
          </FormControl>
        </DialogContent>
      </Dialog>
    </>)
}
export { CustomDialog };