import { useMemo, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import {
    Avatar, Box, FormControl, MenuItem, Pagination,
    Select, SelectChangeEvent, Stack, styled,
} from '@mui/material';
import styles from './AdminLeave.module.scss'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { AddEditLeaveDg, CustomPopover, DeleteDialog } from './CustomDialogs';


function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
    id: keyof Data;
    label: string;
}

const headCells: readonly HeadCell[] = [
    { id: 'employee', label: 'Employee', },
    { id: 'leaveType', label: 'Leave Type', },
    { id: 'from', label: 'From', },
    { id: 'to', label: 'To', },
    { id: 'noOfDay', label: 'No of Days', },
    { id: 'reason', label: 'Reason', },
    { id: 'status', label: 'Status', },
    { id: 'action', label: 'Action', },
];
interface EnhancedTableProps {
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler =
        (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

    return (
        <TableHead>
            <TableRow className={styles.tableHeading}>
                {headCells.map((headCell) => (
                    <TableCell
                        sx={{ fontWeight: 600 }}
                        align='center'
                        key={headCell.id}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const StyledTableRow = styled(TableRow)(() => ({
    '&:nth-of-type(odd)': {
        backgroundColor: "#e2e4e6",
    },
    '&:nth-of-type(even)': {
        backgroundColor: "white",
    },
}));

interface Data {
    id: number;
    avatar: any;
    employee: string;
    leaveType: string;
    from: string;
    to: string;
    noOfDay: number,
    reason: string;
    status: React.ReactNode;
    action: React.ReactNode;
}

function createData(
    id: number,
    avatar: any,
    employee: string,
    leaveType: string,
    from: string,
    to: string,
    noOfDay: number,
    reason: string,
    status: string,
    action: React.ReactNode,
): Data {
    return {
        id,
        avatar,
        employee,
        leaveType,
        from,
        to,
        noOfDay,
        reason,
        status,
        action,
    };
}
const diffStatus = ['New', 'Panding', 'Approved', 'Declined']

export default function EnhancedTable() {
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<keyof Data>('id');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [selectStatus, setStatus] = useState<string>('New')
    const [openPopover, setopenPopover] = useState<null | HTMLElement>(null);
    const [opendelete, setDelete] = useState<boolean>(false)
    const [openAddleave, setAddLeave] = useState<HTMLElement | null>(null)
    const [popoverPosition, setPopoverPosition] = useState({ top: 0, left: 0 });

    let rows: any = [
        createData(1, './man.jpg', 'Cupcake', 'Cupcake', 'Cupcake', 'Cupcake', 5, 'Cupcake', 'new', ''),
        createData(2, './man.jpg', 'Cupcake', 'Cupcake', 'Cupcake', 'Cupcake', 5, 'Cupcake', 'new', ''),
    ];
    const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
        const rect = event.currentTarget.getBoundingClientRect();
        setPopoverPosition({
            top: rect.bottom,
            left: rect.left
        });
        setopenPopover(openPopover ? null : event.currentTarget);
    };

    const ChangeStatus = (event: SelectChangeEvent) => {
        setStatus(event.target.value);
    };

    //for popuover
    const openPop = Boolean(openPopover);
    const openEdit = (event: React.MouseEvent<HTMLElement>) => {
        setAddLeave(openAddleave ? null : event.currentTarget);

    }

    const openDeleteDg = () => {
        setDelete(true);
    }
    //edit
    const openeditdg = Boolean(openAddleave)

    //for deleteDialogBox
    const delateData = () => {

    };


    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof Data,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleStackPaginationClick = (event: unknown | null, newPage: number) => {
        setPage(newPage - 1); // Adjust newPage to match zero-indexed page state
    };


    const visibleRows = useMemo(
        () =>
            stableSort(rows, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, page, rowsPerPage],
    );

    return (
        <>
            <Box sx={{ width: '100%' }}>
                <div className={styles.tableHeader}>
                    <p>show</p>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={() => { }}
                        onRowsPerPageChange={() => { }}
                        sx={{

                            '.MuiTablePagination-spacer': { display: 'none' },
                            '.MuiToolbar-root': { paddingLeft: 0, minHeight: 0 },
                            '.MuiTablePagination-actions': { display: 'none' },
                            '.MuiTablePagination-displayedRows': { display: 'none' },
                            '.MuiTablePagination-selectLabel': { display: 'none' },
                        }}
                    />
                    <p>Entries</p>
                </div>
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                    >
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                        />
                        <TableBody>
                            {visibleRows.map((row) => {
                                const labelId = `enhanced-table-${rows.id}`;
                                return (
                                    <StyledTableRow >
                                        <TableCell
                                            align="right"
                                            id={labelId}
                                            scope="row"
                                        >
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                <Stack direction="row" spacing={2}>
                                                    <Avatar
                                                        alt={`${row.employee}'s Avatar`}
                                                        src={rows.avatar} />
                                                </Stack>
                                                {row.employee}
                                            </div>
                                        </TableCell>
                                        <TableCell align="center">{row.leaveType}</TableCell>
                                        <TableCell align="center">{row.from}</TableCell>
                                        <TableCell align="center">{row.to}</TableCell>
                                        <TableCell align="center">{row.noOfDay}</TableCell>
                                        <TableCell align="center">{row.reason}</TableCell>
                                        <TableCell align="center">
                                            <FormControl>
                                                <Select
                                                    sx={{ height: '2rem', borderRadius: '3rem', '.MuiSelect-select': { display: 'flex' } }}
                                                    value={selectStatus}
                                                    onChange={ChangeStatus}
                                                >
                                                    {diffStatus.map((item: string, index: number) => (
                                                        <MenuItem
                                                            value={item}
                                                            key={index}
                                                        >
                                                            <RadioButtonCheckedIcon
                                                                className={`${item === 'New' ? styles.new : ''}
                                                          ${item === 'Panding' ? styles.panding : ''}
                                                          ${item === 'Approved' ? styles.approved : ''}
                                                          ${item === 'Declined' ? styles.declined : ''}`
                                                                }
                                                            />
                                                            {item}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </TableCell>
                                        <TableCell align="center">
                                            <i style={{ position: 'relative' }} onClick={handleOpenPopover}>
                                                <MoreVertIcon />
                                            </i>
                                        </TableCell>
                                    </StyledTableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div style={{ float: 'right' }}>
                    <Stack spacing={2}>
                        <Pagination
                            count={Math.ceil(rows.length / rowsPerPage)}
                            page={page + 1} 
                            onChange={handleStackPaginationClick}
                            variant="outlined"
                            shape="rounded"
                            hidePrevButton
                            hideNextButton
                            className={styles.usepagination}
                        />
                    </Stack>
                </div>

            </Box >
            <CustomPopover
                openPopover={openPop}
                closePopover={() => setopenPopover(null)}
                openEdit={openEdit}
                openDeleteDg={openDeleteDg}
                position={popoverPosition}
                 />
            <DeleteDialog
                heading='Delete Leave'
                opendelete={opendelete}
                closeDeleteDg={() => setDelete(false)}
                delateData={delateData} />
            <AddEditLeaveDg
                heading='Edit Leaves'
                openAdd={openeditdg}
                handleClose={() => setAddLeave(null)}
            />
        </>
    )
}
