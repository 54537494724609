import { useSelector } from "react-redux";
import { getUserData } from "../../ReduxToolkit/Slices/LoginSlices";
import { ILocalStorageData } from "../../Interface/Interface";


export const Dashboard = () => {
  const data: ILocalStorageData = useSelector(getUserData);

  return (
    <>
      <h1>{data?.message}</h1>
    </>
  );
};