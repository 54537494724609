import { Avatar, Box, Dialog, DialogContent, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import React, { useState } from 'react';
import styles from './AdminAttendance.module.scss'

interface AdminAttendProp {
    open: boolean;
}

type AttendanceStatus = 'present' | 'absent' | 'half';

function createData(
    id: number,
    employee: string,
    avatar: string,
    attendance: AttendanceStatus[]
) {
    return { id, employee, avatar, attendance };
}

let heading: number[] = [];
for (let i = 1; i <= 31; i++) {
    heading.push(i);
}

const rows = [
    createData(1, 'John Doe', "", ['present', 'present', 'half', 'present', 'absent', 'present', 'present', 'present', 'present', 'present', 'absent', 'present', 'present', 'present', 'present', 'absent', 'present', 'present', 'present', 'present', 'absent', 'present', 'present', 'present', 'present', 'present', 'present', 'absent', 'present', 'present', 'present']),
    createData(2, 'Jane Smith', "", ['absent', 'present', 'present', 'present', 'absent', 'present', 'present', 'present', 'present', 'present', 'absent', 'present', 'present', 'present', 'absent', 'absent', 'present', 'present', 'present', 'absent', 'present', 'present', 'present', 'absent', 'present', 'present', 'absent', 'present', 'absent', 'present', 'present'])
];

const AdminAttendance: React.FC<AdminAttendProp> = ({ open }) => {
    const [opendg, setOpenDg] = useState<HTMLElement | null>(null)

    const openDialog = Boolean(opendg);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setOpenDg(openDialog ? null : event.currentTarget)
    }
    return (
        <Box className={`${open ? styles.open : styles.adminAttendMain_Container}`}>
            <div className={styles.adminAttend_container}>
                <div className={styles.alHeader}>
                    <Typography
                        variant="h5"
                        sx={{ fontWeight: 600 }}>
                        Attendance
                    </Typography>
                    <ul className={styles.headingSubText}>
                        <li>
                            <a href="/dashboard">Dashboard </a>
                        </li>
                        <li>
                            <a href="/"> / Attendance</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} >
                        <TableHead >
                            <TableRow>
                                <TableCell
                                    sx={{ '&.MuiTableCell-root': { fontWeight: 600 } }}>
                                    Employee
                                </TableCell>
                                {heading.map((item: number) => (
                                    <TableCell key={item}
                                        sx={{ '&.MuiTableCell-root': { fontWeight: 600 } }}>
                                        {item}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row, rowIndex) => (
                                <TableRow
                                    key={rowIndex}
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0, backgroundColor: rowIndex % 2 === 0 ? 'white' : '#e2e4e6'
                                        }
                                    }}
                                >
                                    <TableCell component="th" scope="row">
                                        <div className={styles.avatarDiv}>
                                            <Avatar
                                                alt={`${row.employee}'s avatar`}
                                                src={row.avatar}
                                                sx={{ width: 24, height: 24, marginRight: 1 }} />
                                            {row.employee}
                                        </div>
                                    </TableCell>
                                    {row.attendance.map((status, index) => (
                                        <TableCell key={index} >
                                            {status === 'present' && <i onClick={handleClick}><DoneIcon fontSize='small' className={styles.present} /></i>}
                                            {status === 'absent' && <CloseIcon fontSize='small' className={styles.absent} />}
                                            {status === 'half' && (
                                                <>
                                                    <i onClick={handleClick}><DoneIcon fontSize='small' className={styles.present} /></i>
                                                    <CloseIcon fontSize='small' className={styles.absent} />
                                                </>
                                            )}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <Dialog
                open={openDialog}
                onClose={() => setOpenDg(null)}
                className={styles.dgwidth}
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%",
                            maxWidth: "800px",
                        },
                    },
                }}
            >
                <DialogContent>
                    <div className={`${styles.timesheetDflex} ${styles.dgHeading}`}>
                        <Typography variant='h5'>Attendance Info</Typography>
                        <i onClick={() => setOpenDg(null)}><CloseIcon /></i>
                    </div>
                    <div className={styles.dialogContent}>
                        <Paper
                            elevation={4}
                            className={styles.dgItem}>
                            <div className={styles.timesheetDflex}>
                                <Typography
                                    className={styles.dgDarkFont}
                                    variant='h6' >
                                    Timesheet
                                </Typography>
                                <Typography
                                    variant='h6'
                                    className={styles.dgFont}>
                                    11 Mar 2022
                                </Typography>
                            </div>
                            <div className={styles.punchInOut} >
                                <Typography
                                    className={styles.dgDarkFont}
                                    variant="body1">
                                    Punch in at
                                </Typography>
                                <Typography
                                    variant='body1'
                                    className={styles.dgFont}>
                                    Wed, 11th Mar 2023 10.00 AM
                                </Typography>
                            </div>
                            <div className={styles.totalHr}>
                                <Typography
                                    sx={{ position: 'absolute', top: '2.5rem', right: '1.5rem' }}>
                                    1.24 hrs
                                </Typography>
                            </div>
                            <div className={styles.punchInOut}>
                                <Typography
                                    className={styles.dgDarkFont}
                                    variant='body1'>
                                    Punch out at
                                </Typography>
                                <Typography
                                    variant='body1'
                                    className={styles.dgFont}>
                                    Wed, 11th Mar 2023 10.00 AM
                                </Typography>
                            </div>
                            <div className={styles.timesheetDflex}>
                                <div className={styles.breakOverTime}>
                                    <Typography
                                        className={styles.dgDarkFont}
                                        sx={{ textAlign: 'center' }}
                                        variant='body2'>
                                        Break
                                    </Typography>
                                    <Typography
                                        className={styles.dgDarkFont}
                                        variant='body2'>
                                        1.21 hrs
                                    </Typography>
                                </div>
                                <div className={styles.breakOverTime}>
                                    <Typography
                                        className={styles.dgDarkFont}
                                        sx={{ textAlign: 'center' }}
                                        variant='body2'>
                                        Overtime
                                    </Typography>
                                    <Typography
                                        className={styles.dgDarkFont}
                                        variant='body2'>
                                        1.21 hrs
                                    </Typography>
                                </div>
                            </div>
                        </Paper>
                        <Paper elevation={4} className={styles.dgItem}>
                            <div>
                                <Typography
                                    className={styles.dgDarkFont}
                                    sx={{ marginBottom: '2rem' }}
                                    variant='h5'>
                                    Activity
                                </Typography>
                            </div>
                            <ul className={styles.activityTime}>
                                <li >
                                    <div className={styles.activityItem}>
                                        <Typography variant='body2' className={styles.activityText}>Punch in at</Typography>
                                        <Typography variant='body2' className={styles.activityHr}>10.00AM</Typography>
                                    </div>
                                </li>
                                <li >
                                    <div className={styles.activityItem}>
                                        <Typography
                                            variant='body2'
                                            className={styles.activityText}>
                                            Punch in at
                                        </Typography>
                                        <Typography
                                            variant='body2'
                                            className={styles.activityHr}>
                                            10.00AM
                                        </Typography>
                                    </div>
                                </li>
                                <li >
                                    <div className={styles.activityItem}>
                                        <Typography
                                            variant='body2'
                                            className={styles.activityText}>
                                            Punch in at
                                        </Typography>
                                        <Typography
                                            variant='body2'
                                            className={styles.activityHr}>
                                            10.00AM
                                        </Typography>
                                    </div>
                                </li>
                                <li >
                                    <div className={styles.activityItem}>
                                        <Typography
                                            variant='body2'
                                            className={styles.activityText}>
                                            Punch in at
                                        </Typography>
                                        <Typography
                                            variant='body2'
                                            className={styles.activityHr}>
                                            10.00AM
                                        </Typography>
                                    </div>
                                </li>
                                <li >
                                    <div className={styles.activityItem}>
                                        <Typography
                                            variant='body2'
                                            className={styles.activityText}>
                                            Punch in at
                                        </Typography>
                                        <Typography
                                            variant='body2'
                                            className={styles.activityHr}>
                                            10.00AM
                                        </Typography>
                                    </div>
                                </li>
                            </ul>
                        </Paper>
                    </div>
                </DialogContent>
            </Dialog>
        </Box>
    );
};

export default AdminAttendance;
