import React from "react";
import styles from "./Employees.module.scss";
import Gridicon from "../../../Images/grid.png";
import Listicon from "../../../Images/view.png";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Search } from "@mui/icons-material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Editicon from "../../../Images/pencil.png";
import Deleteicon from "../../../Images/delete.png";
import Person1_img from "../../../Images/avatar-02.62b90545c23b82880b99.jpg";
import Person2_img from "../../../Images/avatar-09.e8f0c584f1f04d596b33.jpg";
import Person3_img from "../../../Images/avatar-10.8edf1918832887f285d5.jpg";
import Person4_img from "../../../Images/avatar-05.3d048314aafb33be4315.jpg";
import Person5_img from "../../../Images/avatar-11.9d84d9f09b64ecfa0cab.jpg";
import Person6_img from "../../../Images/avatar-12.b4b0664305cefbe97943.jpg";
import Person7_img from "../../../Images/avatar-13.5725f6f75f95cd8fccbe.jpg";
import Person8_img from "../../../Images/avatar-04.36bf78164b7ce537b371.jpg";
import Person9_img from "../../../Images/avatar-03.f9e5045ddc10da8a97c5.jpg";
import Person10_img from "../../../Images/avatar-08.966ab00bd1245a90eb11.jpg";
import Person11_img from "../../../Images/avatar-19.708103bcecf568639f74.jpg";
import Modal from "@mui/material/Modal";

interface deaprops {
  open: boolean;
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "unset",
  color: theme.palette.text.secondary,
}));

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const options = ["John Doe", "Richard Miles", "John Smith"];

const Employees: React.FC<deaprops> = ({ open }) => {
  const [value, setValue] = React.useState<string | null>(options[0]);
  const [inputValue, setInputValue] = React.useState("");

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open_edit = Boolean(anchorEl);
  const [modal, setmodal] = React.useState(false);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlemodalOpen = () => {
    setmodal(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setmodal(false);
  };

  return (
    <div>
      <Box
        className={` ${open ? styles.open : styles.employeesMain_Container}`}
      >
        <div className={styles.Employee_topnav}>
          <div className={styles.top_text}>
            <h2>Employee</h2>
            <h3>Dashboard / Employee</h3>
          </div>
          <div className={styles.top_buttons}>
            <img src={Gridicon} alt="Gridicon" />
            <img src={Listicon} alt="Listicon" />
            <Button variant="contained" onClick={handlemodalOpen}>
              + &nbsp; Add Employee
            </Button>

            <Modal
              open={modal}
              onClose={handleClose}
              aria-labelledby="parent-modal-title"
              aria-describedby="parent-modal-description"
            >
              <Box sx={{ ...style, width: 750, height: "90vh" }}>
                <h2>Add Employee </h2>
              </Box>
            </Modal>
          </div>
        </div>

        <div className={styles.search_row}>
          <Box sx={{ width: "100%" }}>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              className={styles.search_line}
            >
              <Grid item xs={3}>
                <Item className={styles.search_box}>
                  <TextField
                    id="filled-basic"
                    label="Employee-ID"
                    variant="filled"
                    style={{ border: "none" }}
                    className={styles.employee_id}
                  />
                </Item>
              </Grid>
              <Grid item xs={3}>
                <Item className={styles.search_box}>
                  {" "}
                  <TextField
                    id="filled-basic"
                    label="Employee Name"
                    variant="filled"
                    style={{ border: "none" }}
                    className={styles.employee_id}
                  />
                </Item>
              </Grid>
              <Grid item xs={3}>
                <Item className={styles.search_box}>
                  <Autocomplete
                    value={value}
                    onChange={(event: any, newValue: string | null) => {
                      setValue(newValue);
                    }}
                    inputValue={inputValue}
                    onInputChange={(event, newInputValue) => {
                      setInputValue(newInputValue);
                    }}
                    id="controllable-states-demo"
                    options={options}
                    sx={{}}
                    className={styles.search_down}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Designation"
                        className={styles.employee_designation}
                      />
                    )}
                  />
                </Item>
              </Grid>
              <Grid item xs={3}>
                <Item className={styles.search_box}>
                  {" "}
                  <Button variant="contained" className={styles.Search_btn}>
                    Search
                  </Button>
                </Item>
              </Grid>
            </Grid>
          </Box>
        </div>

        <div className={styles.Employee_list}>
          <Box sx={{ width: "100%" }}>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 1, md: 3 }}
              className={styles.boxrow_2}
            >
              <Grid item xs={3}>
                <Item className={styles.person_box}>
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={open_edit ? "long-menu" : undefined}
                    aria-expanded={open_edit ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                    className={styles.more_icon}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open_edit}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                    className={styles.option_box}
                  >
                    <MenuItem
                      onClick={handleClose}
                      className={styles.Item_edit}
                    >
                      <img
                        src={Editicon}
                        alt="Editicon"
                        style={{ width: "35%", height: "2vh" }}
                      />
                      Edit
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                      <img
                        src={Deleteicon}
                        alt="Editicon"
                        style={{ width: "50%", height: "2vh" }}
                      />
                      Delete
                    </MenuItem>
                  </Menu>
                  <div className={styles.employe_Details}>
                    <img src={Person1_img} alt="Person1_img" />
                    <h3>John Doe</h3>
                    <p>Web Designer</p>
                  </div>
                </Item>
              </Grid>
              <Grid item xs={3}>
                <Item className={styles.person_box}>
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={open_edit ? "long-menu" : undefined}
                    aria-expanded={open_edit ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                    className={styles.more_icon}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open_edit}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                    className={styles.option_box}
                  >
                    <MenuItem
                      onClick={handleClose}
                      className={styles.Item_edit}
                    >
                      <img
                        src={Editicon}
                        alt="Editicon"
                        style={{ width: "35%", height: "2vh" }}
                      />
                      Edit
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                      <img
                        src={Deleteicon}
                        alt="Editicon"
                        style={{ width: "50%", height: "2vh" }}
                      />
                      Delete
                    </MenuItem>
                  </Menu>
                  <div className={styles.employe_Details}>
                    <img src={Person2_img} alt="Person2_img" />
                    <h3>Richard Miles</h3>
                    <p>Web Developer</p>
                  </div>
                </Item>
              </Grid>
              <Grid item xs={3}>
                <Item className={styles.person_box}>
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={open_edit ? "long-menu" : undefined}
                    aria-expanded={open_edit ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                    className={styles.more_icon}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open_edit}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                    className={styles.option_box}
                  >
                    <MenuItem
                      onClick={handleClose}
                      className={styles.Item_edit}
                    >
                      <img
                        src={Editicon}
                        alt="Editicon"
                        style={{ width: "35%", height: "2vh" }}
                      />
                      Edit
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                      <img
                        src={Deleteicon}
                        alt="Editicon"
                        style={{ width: "50%", height: "2vh" }}
                      />
                      Delete
                    </MenuItem>
                  </Menu>
                  <div className={styles.employe_Details}>
                    <img src={Person3_img} alt="Person3_img" />
                    <h3>John Smith</h3>
                    <p>Android Developer</p>
                  </div>
                </Item>
              </Grid>
              <Grid item xs={3}>
                <Item className={styles.person_box}>
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={open_edit ? "long-menu" : undefined}
                    aria-expanded={open_edit ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                    className={styles.more_icon}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open_edit}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                    className={styles.option_box}
                  >
                    <MenuItem
                      onClick={handleClose}
                      className={styles.Item_edit}
                    >
                      <img
                        src={Editicon}
                        alt="Editicon"
                        style={{ width: "35%", height: "2vh" }}
                      />
                      Edit
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                      <img
                        src={Deleteicon}
                        alt="Editicon"
                        style={{ width: "50%", height: "2vh" }}
                      />
                      Delete
                    </MenuItem>
                  </Menu>
                  <div className={styles.employe_Details}>
                    <img src={Person4_img} alt="Person4_img" />
                    <h3>Mike Litorus</h3>
                    <p>IOS Developer</p>
                  </div>
                </Item>
              </Grid>
            </Grid>
          </Box>

          <Box sx={{ width: "100%" }}>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              className={styles.boxrow_2}
            >
              <Grid item xs={3}>
                <Item className={styles.person_box}>
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={open_edit ? "long-menu" : undefined}
                    aria-expanded={open_edit ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                    className={styles.more_icon}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open_edit}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                    className={styles.option_box}
                  >
                    <MenuItem
                      onClick={handleClose}
                      className={styles.Item_edit}
                    >
                      <img
                        src={Editicon}
                        alt="Editicon"
                        style={{ width: "35%", height: "2vh" }}
                      />
                      Edit
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                      <img
                        src={Deleteicon}
                        alt="Editicon"
                        style={{ width: "50%", height: "2vh" }}
                      />
                      Delete
                    </MenuItem>
                  </Menu>
                  <div className={styles.employe_Details}>
                    <img src={Person5_img} alt="Person5_img" />
                    <h3>Wilmer Deluna</h3>
                    <p>Team Leader</p>
                  </div>
                </Item>
              </Grid>
              <Grid item xs={3}>
                <Item className={styles.person_box}>
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={open_edit ? "long-menu" : undefined}
                    aria-expanded={open_edit ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                    className={styles.more_icon}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open_edit}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                    className={styles.option_box}
                  >
                    <MenuItem
                      onClick={handleClose}
                      className={styles.Item_edit}
                    >
                      <img
                        src={Editicon}
                        alt="Editicon"
                        style={{ width: "35%", height: "2vh" }}
                      />
                      Edit
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                      <img
                        src={Deleteicon}
                        alt="Editicon"
                        style={{ width: "50%", height: "2vh" }}
                      />
                      Delete
                    </MenuItem>
                  </Menu>
                  <div className={styles.employe_Details}>
                    <img src={Person6_img} alt="Person6_img" />
                    <h3>Jeffrey Warden</h3>
                    <p>Web Developer</p>
                  </div>
                </Item>
              </Grid>
              <Grid item xs={3}>
                <Item className={styles.person_box}>
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={open_edit ? "long-menu" : undefined}
                    aria-expanded={open_edit ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                    className={styles.more_icon}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open_edit}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                    className={styles.option_box}
                  >
                    <MenuItem
                      onClick={handleClose}
                      className={styles.Item_edit}
                    >
                      <img
                        src={Editicon}
                        alt="Editicon"
                        style={{ width: "35%", height: "2vh" }}
                      />
                      Edit
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                      <img
                        src={Deleteicon}
                        alt="Editicon"
                        style={{ width: "50%", height: "2vh" }}
                      />
                      Delete
                    </MenuItem>
                  </Menu>
                  <div className={styles.employe_Details}>
                    <img src={Person7_img} alt="Person7_img" />
                    <h3>Bernardo Galaviz</h3>
                    <p>Web Developer</p>
                  </div>
                </Item>
              </Grid>
              <Grid item xs={3}>
                <Item className={styles.person_box}>
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={open_edit ? "long-menu" : undefined}
                    aria-expanded={open_edit ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                    className={styles.more_icon}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open_edit}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                    className={styles.option_box}
                  >
                    <MenuItem
                      onClick={handleClose}
                      className={styles.Item_edit}
                    >
                      <img
                        src={Editicon}
                        alt="Editicon"
                        style={{ width: "35%", height: "2vh" }}
                      />
                      Edit
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                      <img
                        src={Deleteicon}
                        alt="Editicon"
                        style={{ width: "50%", height: "2vh" }}
                      />
                      Delete
                    </MenuItem>
                  </Menu>
                  <div className={styles.employe_Details}>
                    <img src={Person8_img} alt="Person8_img" />
                    <h3>Lesley Gatlin</h3>
                    <p>Android Developer</p>
                  </div>
                </Item>
              </Grid>
            </Grid>
          </Box>

          <Box sx={{ width: "100%" }}>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              className={styles.boxrow_2}
            >
              <Grid item xs={3}>
                <Item className={styles.person_box}>
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={open_edit ? "long-menu" : undefined}
                    aria-expanded={open_edit ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                    className={styles.more_icon}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open_edit}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                    className={styles.option_box}
                  >
                    <MenuItem
                      onClick={handleClose}
                      className={styles.Item_edit}
                    >
                      <img
                        src={Editicon}
                        alt="Editicon"
                        style={{ width: "35%", height: "2vh" }}
                      />
                      Edit
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                      <img
                        src={Deleteicon}
                        alt="Editicon"
                        style={{ width: "50%", height: "2vh" }}
                      />
                      Delete
                    </MenuItem>
                  </Menu>
                  <div className={styles.employe_Details}>
                    <img src={Person9_img} alt="Person9_img" />
                    <h3>Tarah Shropshire</h3>
                    <p>Android Developer</p>
                  </div>
                </Item>
              </Grid>
              <Grid item xs={3}>
                <Item className={styles.person_box}>
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={open_edit ? "long-menu" : undefined}
                    aria-expanded={open_edit ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                    className={styles.more_icon}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open_edit}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                    className={styles.option_box}
                  >
                    <MenuItem
                      onClick={handleClose}
                      className={styles.Item_edit}
                    >
                      <img
                        src={Editicon}
                        alt="Editicon"
                        style={{ width: "35%", height: "2vh" }}
                      />
                      Edit
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                      <img
                        src={Deleteicon}
                        alt="Editicon"
                        style={{ width: "50%", height: "2vh" }}
                      />
                      Delete
                    </MenuItem>
                  </Menu>
                  <div className={styles.employe_Details}>
                    <img src={Person10_img} alt="Person10_img" />
                    <h3>Catherine Manseau</h3>
                    <p>Android Developer</p>
                  </div>
                </Item>
              </Grid>
              <Grid item xs={3}>
                <Item className={styles.person_box}>
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={open_edit ? "long-menu" : undefined}
                    aria-expanded={open_edit ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                    className={styles.more_icon}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open_edit}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                    className={styles.option_box}
                  >
                    <MenuItem
                      onClick={handleClose}
                      className={styles.Item_edit}
                    >
                      <img
                        src={Editicon}
                        alt="Editicon"
                        style={{ width: "35%", height: "2vh" }}
                      />
                      Edit
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                      <img
                        src={Deleteicon}
                        alt="Editicon"
                        style={{ width: "50%", height: "2vh" }}
                      />
                      Delete
                    </MenuItem>
                  </Menu>
                  <div className={styles.employe_Details}>
                    <img src={Person3_img} alt="Person3_img" />
                    <h3>Lesley Gatlin</h3>
                    <p>Android Developer</p>
                  </div>
                </Item>
              </Grid>
              <Grid item xs={3}>
                <Item className={styles.person_box}>
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={open_edit ? "long-menu" : undefined}
                    aria-expanded={open_edit ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                    className={styles.more_icon}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open_edit}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                    className={styles.option_box}
                  >
                    <MenuItem
                      onClick={handleClose}
                      className={styles.Item_edit}
                    >
                      <img
                        src={Editicon}
                        alt="Editicon"
                        style={{ width: "35%", height: "2vh" }}
                      />
                      Edit
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                      <img
                        src={Deleteicon}
                        alt="Editicon"
                        style={{ width: "50%", height: "2vh" }}
                      />
                      Delete
                    </MenuItem>
                  </Menu>
                  <div className={styles.employe_Details}>
                    <img src={Person11_img} alt="Person11_img" />
                    <h3>Tarah Shropshire</h3>
                    <p>Android Developer</p>
                  </div>
                </Item>
              </Grid>
            </Grid>
          </Box>
        </div>
      </Box>
    </div>
  );
};

export default Employees;
