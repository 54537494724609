import React, { useState, useEffect } from 'react';
import { IconButton, Menu, MenuItem } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpTwoToneIcon from '@mui/icons-material/KeyboardArrowUpTwoTone';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import "./HeaderStyle.scss";
import ActiveImage from './ActiveImage';

interface AdminDropdownProps {
    onMyProfileClick: () => void;
    onSettingsClick: () => void;
    onLogoutClick: () => void;
}

const AdminDropdown: React.FC<AdminDropdownProps> = ({
    onMyProfileClick,
    onSettingsClick,
    onLogoutClick,
}) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [isMediumScreen, setIsMediumScreen] = useState(false);
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const [iconOpen, setIconOpen] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            setIsMediumScreen(width <= 990 && width >= 576);
            setIsSmallScreen(width <= 575);
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setIconOpen(!iconOpen);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setIconOpen(false);
    };

    return (
        <div className="admin-dropdown">
            <li>
                <li className='avatrfullcsreen'>
                    <ActiveImage src="avatar.jpg" alt="Avatar" active={true} />
                </li>
                {isMediumScreen || isSmallScreen ? (
                    <IconButton onClick={handleMenuOpen}>
                        {isSmallScreen ? <MoreVertIcon style={{ color: "#fff" }} /> : <ActiveImage src="avatar.jpg" alt="Avatar" active={true} />}
                    </IconButton>
                ) : (
                    <div>
                        <IconButton onClick={handleMenuOpen}>
                            <span className='admin-lebal'>Admin</span>
                            {iconOpen ? (
                                <KeyboardArrowUpTwoToneIcon className='admin-arrow' />
                            ) : (
                                <KeyboardArrowDownIcon className='admin-arrow' />
                            )}
                        </IconButton>
                    </div>
                )}
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                >
                    <MenuItem onClick={onMyProfileClick}>My Profile</MenuItem>
                    <MenuItem onClick={onSettingsClick}>Settings</MenuItem>
                    <MenuItem onClick={onLogoutClick}>Logout</MenuItem>
                </Menu>
            </li>
        </div>
    );
};

export default AdminDropdown;
