import { LocalizationProvider } from '@mui/x-date-pickers-pro/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangeCalendar } from '@mui/x-date-pickers-pro/DateRangeCalendar';
import { Dayjs } from 'dayjs';
import { useState } from 'react';
import { Box, Button, Divider, Popper, TextField } from '@mui/material';
import styles from './DealDashboard.module.scss'


const DealCalender = () => {
    const [anchorEl, setAnchorEl] = useState<HTMLInputElement | null>(null);
    const [dateRange, setDateRange] = useState<[Dayjs | null, Dayjs | null]>([null, null]);
    const [tempDateRange, setTempDateRange] = useState<[Dayjs | null, Dayjs | null]>([null, null]);

    const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
        setAnchorEl(event.currentTarget);
        setTempDateRange(dateRange);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDateChange = (newDateRange: [Dayjs | null, Dayjs | null]) => {
        setTempDateRange(newDateRange);
    };

    const handleApply = () => {
        setDateRange(tempDateRange);
        handleClose();
    };

    const popen = Boolean(anchorEl);
    const id = popen ? 'popper-id' : undefined;

    const formatDate = (date: Dayjs | null) => {
        if (!date) return '';
        return date.toDate().toLocaleDateString();
    };

    const textFieldValue = dateRange[0] && dateRange[1]
        ? `${formatDate(dateRange[0])} - ${formatDate(dateRange[1])}`
        : '';

    return (
        <>
            <TextField
                variant='standard'
                InputProps={{
                    disableUnderline: true,
                }}
                size="small"
                onFocus={handleFocus}
                value={textFieldValue}

            />
            <Box sx={{ height: 100 }}>
                <Popper
                    sx={{ opacity: 1 }}
                    id={id}
                    open={popen}
                    anchorEl={anchorEl}
                    placement='bottom-end'
                >
                    <Box sx={{ backgroundColor: '#f1eee9', display: 'flex' }}>
                        <div>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateRangeCalendar
                                    value={tempDateRange}
                                    onChange={handleDateChange}
                                />
                                <Divider />
                                <div className={styles.calenderFooter}>
                                    <p> {`${formatDate(tempDateRange[0])} - ${formatDate(tempDateRange[1])}`}</p>
                                    <Button variant='text'
                                        sx={{
                                            marginRight: 2,
                                            marginLeft: 1,
                                            color: 'white',
                                            backgroundColor: '#fb8c00',
                                            '&:hover': {
                                                backgroundColor: '#fb8c00',
                                            },
                                        }}
                                        onClick={handleClose}>Cancel</Button>
                                    <Button variant='text'
                                        sx={{
                                            marginRight: 1,
                                            color: 'white',
                                            backgroundColor: '#fb8c00',
                                            '&:hover': {
                                                backgroundColor: '#fb8c00',
                                            },
                                        }}
                                        onClick={handleApply}>Apply</Button>
                                </div>
                            </LocalizationProvider>
                        </div>
                    </Box>
                </Popper>
            </Box>
        </>
    )
}

export default DealCalender

