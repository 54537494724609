import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import styles from './AdminLeave.module.scss'
import { Box, Button, Dialog, DialogContent, FormControl, Popover, TextField, TextareaAutosize, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import DateRangeIcon from '@mui/icons-material/DateRange';
import CloseIcon from '@mui/icons-material/Close';

interface popoverProps {
  openPopover: boolean,
  closePopover: () => void,
  openEdit: (event: React.MouseEvent<HTMLElement>) => void;
  openDeleteDg: () => void,
  position: { top: number; left: number };

}

const CustomPopover = ({ openPopover, closePopover, position, openEdit, openDeleteDg }: popoverProps) => {

  const handleEditClick = (event: React.MouseEvent<HTMLElement>) => {
    closePopover();
    openEdit(event);
  };
  return (
    <Popover
      open={openPopover}
      onClose={closePopover}
      anchorReference="anchorPosition"
      anchorPosition={{ top: position.top, left: position.left }}
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <button
          className={styles.popupBtn}
          onClick={handleEditClick}>
          <i><ModeEditIcon /> </i>
          Edit
        </button>
        <button
          className={styles.popupBtn}
          onClick={openDeleteDg}>
          <i><DeleteIcon /></i>
          Delete
        </button>
      </div>
    </Popover>
  )
}

interface deleteDgProps {
  heading: string;
  opendelete: boolean,
  closeDeleteDg: () => void,
  delateData: () => void
}

const DeleteDialog = ({ heading, opendelete, closeDeleteDg, delateData }: deleteDgProps) => {
  return (
    <Dialog
      open={opendelete}
      onClose={closeDeleteDg}
      className={styles.dgwidth}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "500px",
          },
        },
      }}
    >
      <Typography
        variant='h4'
        sx={{ textAlign: 'center', paddingTop: '1rem ' }}>
        {heading}
      </Typography>
      <DialogContent>
        <Typography
          variant='body1'
          sx={{ textAlign: 'center', paddingTop: '0%' }}>
          Are you sure want to delere?
        </Typography>
      </DialogContent>
      <div
        style={{ display: 'flex', justifyContent: 'space-around' }}>
        <Button
          className={styles.dialogBoxBtn}
          onClick={() => (delateData())} >
          Delete
        </Button>
        <Button
          className={styles.dialogBoxBtn}
          onClick={closeDeleteDg}>
          Cancle
        </Button>
      </div>
    </Dialog>
  )
}

interface addEditProps {
  openAdd: boolean,
  handleClose: () => void,
  heading: string,
}
const AddEditLeaveDg = ({ openAdd, handleClose, heading }: addEditProps) => {
  return (
    <>
      <Dialog
        open={openAdd}
        onClose={handleClose}
        className={styles.dgwidth}

        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "500px",
            },
          },
        }}
      >
        <DialogContent
          sx={{
            '&.MuiDialogContent-root': {
              overflowX: 'hidden'
            }
          }}>
          <FormControl sx={{
            '&.MuiFormControl-root': {
              width: "100%",
            }
          }}>
            <Box
              className={styles.dialogHeader}>
              <Typography
                variant="h5"
                sx={{ fontWeight: 500, margin: '.5rem' }}>
                {heading}
              </Typography>
              <i onClick={handleClose}>
                <CloseIcon />
              </i>
            </Box>
            <Typography
              variant="body1"
              sx={{ marginTop: '.5rem' }}>
              Leave Type
              <span>*</span>
            </Typography>

            <TextField required />

            <Typography
              variant="body1"
              sx={{ marginTop: '.5rem' }}>
              From
              <span>*</span>
            </Typography>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}>
              <DatePicker
                label="From"
              />
              <i className={styles.dateicondg} ><DateRangeIcon /></i>
            </LocalizationProvider>

            <Typography
              variant="body1"
              sx={{ marginTop: '.5rem' }}>
              To
              <span>*</span>
            </Typography >
            <LocalizationProvider
              dateAdapter={AdapterDayjs}>
              <DatePicker
                label="From"
              />
              <i className={styles.dateicondg}><DateRangeIcon /></i>
            </LocalizationProvider>

            <Typography
              variant="body1"
              sx={{ marginTop: '.5rem' }}>
              Number of Day
              <span>*</span>
            </Typography>
            <TextField disabled className={styles.disableInput} />

            <Typography
              variant="body1"
              sx={{ marginTop: '.5rem' }}>
              Ramaining Leaves
              <span>*</span>
            </Typography>

            <TextField disabled className={styles.disableInput} />

            <Typography
              variant="body1"
              sx={{ marginTop: '.5rem' }}>
              Leave Reason
              <span>*</span>
            </Typography>
            <TextareaAutosize minRows={8} />

            <Button
              className={styles.dialogBoxBtn}
            >Submit</Button>

          </FormControl>
        </DialogContent>
      </Dialog>
    </>
  )
}
export { CustomPopover, DeleteDialog, AddEditLeaveDg }