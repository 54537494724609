import React from "react";
import styles from "./Admin.module.scss";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import product_img from "../../../Images/products.png";
import client_img from "../../../Images/dollar.png";
import Task_img from "../../../Images/dimond.png";
import Employees_img from "../../../Images/contact.png";
import { BarChart } from "@mui/x-charts/BarChart";
import { LineChart } from "@mui/x-charts/LineChart";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import Absent_img from "../../../Images/user.png";
import Button from "@mui/material/Button";
import ArowImg from "../../../Images/up-down.png";

interface deaprops {
  open: boolean;
}

const uData = [4000, 3000, 2000, 2780, 1890, 2390, 3490];
const pData = [2400, 1398, 9800, 3908, 4800, 3800, 4300];
const xLabels = [
  "Page A",
  "Page B",
  "Page C",
  "Page D",
  "Page E",
  "Page F",
  "Page G",
];

const uData2 = [4000, 3000, 2000, 2780, 1890, 2390, 3490];
const pData2 = [2400, 1398, 9800, 3908, 4800, 3800, 4300];
const xLabels2 = [
  "Page A",
  "Page B",
  "Page C",
  "Page D",
  "Page E",
  "Page F",
  "Page G",
];

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

const Admin: React.FC<deaprops> = ({ open }) => {
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "unset",
    color: theme.palette.text.secondary,
  }));

  return (
    <Box className={` ${open ? styles.open : styles.adminMain_Container}`}>
      <div className={styles.Admin_maincontainer}>
        <div className={styles.page_heading}>
          <h3>Welcome Admin !</h3>
          <h4>Dashboard</h4>
        </div>

        <div className={styles.top_ranking}>
          <Box sx={{ width: "100%", height: "15vh" }}>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={3}>
                <Item className={styles.ranking_box}>
                  <div className={styles.project_box}>
                    <img src={product_img} alt="product_img" />
                  </div>
                  <div className={styles.product_text}>
                    <h3>112</h3>
                    <span>Projects</span>
                  </div>
                </Item>
              </Grid>
              <Grid item xs={3}>
                <Item className={styles.ranking_box}>
                  <div className={styles.project_box}>
                    <img
                      src={client_img}
                      alt="client_img"
                      className={styles.client_img}
                    />
                  </div>
                  <div className={styles.product_text}>
                    <h3>44</h3>
                    <span>Client</span>
                  </div>
                </Item>
              </Grid>
              <Grid item xs={3}>
                <Item className={styles.ranking_box}>
                  <div className={styles.project_box}>
                    <img src={Task_img} alt="client_img" />
                  </div>
                  <div className={styles.product_text}>
                    <h3>37</h3>
                    <span>Tasks</span>
                  </div>
                </Item>
              </Grid>
              <Grid item xs={3}>
                <Item className={styles.ranking_box}>
                  <div className={styles.project_box}>
                    <img src={Employees_img} alt="client_img" />
                  </div>
                  <div className={styles.product_text}>
                    <h3>218</h3>
                    <span>Employees</span>
                  </div>
                </Item>
              </Grid>
            </Grid>
          </Box>
        </div>

        <div className={styles.graph_container}>
          <div className={styles.graphone}>
            <h1></h1>
            {
              <BarChart
                width={715}
                height={410}
                series={[
                  { data: pData, label: "Total Income", id: "pvId" },
                  { data: uData, label: "Total Outcome", id: "uvId" },
                ]}
                xAxis={[{ data: xLabels, scaleType: "band" }]}
                className={styles.total_review}
              />
            }
          </div>

          <div className={styles.graphtwo}>
            <LineChart
              width={715}
              height={410}
              series={[
                { data: pData2, label: "Total Sales" },
                { data: uData2, label: "Total Revenue" },
              ]}
              xAxis={[{ scaleType: "point", data: xLabels2 }]}
              className={styles.sales_overview}
            />
          </div>
        </div>
        <div className={styles.Earn_container}>
          <Box sx={{ width: "100%" }}>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={3}>
                <Item className={styles.employe_box}>
                  <div className={styles.employe_text}>
                    <h3>New Employees</h3>
                    <span>+10%</span>
                  </div>
                  <h2>10</h2>
                  <div className={styles.progress}>
                    <BorderLinearProgress
                      variant="determinate"
                      value={60}
                      style={{ width: "100%", height: "1vh" }}
                      className={styles.employe_progress}
                    />
                    <h3>
                      Overall Employees <span> 218 </span>
                    </h3>
                  </div>
                </Item>
              </Grid>
              <Grid item xs={3}>
                <Item className={styles.employe_box}>
                  <div className={styles.employe_text}>
                    <h3>Earnings</h3>
                    <span>+12.5%</span>
                  </div>
                  <h2>$1,42,300</h2>
                  <div className={styles.progress}>
                    <BorderLinearProgress
                      variant="determinate"
                      value={60}
                      style={{ width: "100%", height: "1vh" }}
                      className={styles.employe_progress}
                    />
                    <h3>
                      Previous Month<span> $1,15,852 </span>
                    </h3>
                  </div>
                </Item>
              </Grid>
              <Grid item xs={3}>
                <Item className={styles.employe_box}>
                  <div className={styles.employe_text}>
                    <h3>Expenses</h3>
                    <span>-2.8%</span>
                  </div>
                  <h2>$8,500</h2>
                  <div className={styles.progress}>
                    <BorderLinearProgress
                      variant="determinate"
                      value={60}
                      style={{ width: "100%", height: "1vh" }}
                      className={styles.employe_progress}
                    />
                    <h3>
                      Previous Month <span> $7,500 </span>
                    </h3>
                  </div>
                </Item>
              </Grid>
              <Grid item xs={3}>
                <Item className={styles.employe_box}>
                  {" "}
                  <div className={styles.employe_text}>
                    <h3>Profit</h3>
                    <span>-75%</span>
                  </div>
                  <h2>$1,12,000</h2>
                  <div className={styles.progress}>
                    <BorderLinearProgress
                      variant="determinate"
                      value={60}
                      style={{ width: "100%", height: "1vh" }}
                      className={styles.employe_progress}
                    />
                    <h3>
                      Previous Month <span> $1,42,000 </span>
                    </h3>
                  </div>
                </Item>
              </Grid>
            </Grid>
          </Box>
        </div>

        <div className={styles.Statistics}>
          <Box sx={{ width: "100%", height: "50vh" }}>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={4}>
                <Item className={styles.Statistics_box}>
                  <h1>Statistics</h1>
                  <div className={styles.status}>
                    <div className={styles.Statistics_text}>
                      <p>Today Leave </p>
                      <strong>
                        4 <small>/ 65</small>
                      </strong>
                    </div>
                    <BorderLinearProgress
                      variant="determinate"
                      value={50}
                      style={{ width: "90%", height: "0.5vh" }}
                      className={styles.Statistics_progress}
                    />
                  </div>

                  <div className={styles.status}>
                    <div className={styles.Statistics_text}>
                      <p>Pending Invoice </p>
                      <strong>
                        15 <small>/ 92</small>
                      </strong>
                    </div>
                    <BorderLinearProgress
                      variant="determinate"
                      value={50}
                      style={{ width: "90%", height: "0.5vh" }}
                      className={styles.Statistics_progress}
                    />
                  </div>

                  <div className={styles.status}>
                    <div className={styles.Statistics_text}>
                      <p>Completed Projects </p>
                      <strong>
                        85 <small>/ 112</small>
                      </strong>
                    </div>
                    <BorderLinearProgress
                      variant="determinate"
                      value={70}
                      style={{ width: "90%", height: "0.5vh" }}
                      className={styles.Statistics_progress}
                    />
                  </div>

                  <div className={styles.status}>
                    <div className={styles.Statistics_text}>
                      <p>Open Tickets </p>
                      <strong>
                        190 <small>/ 212</small>
                      </strong>
                    </div>
                    <BorderLinearProgress
                      variant="determinate"
                      value={80}
                      style={{ width: "90%", height: "0.5vh" }}
                      className={styles.Statistics_progress}
                    />
                  </div>

                  <div className={styles.status}>
                    <div className={styles.Statistics_text}>
                      <p>Closed Tickets </p>
                      <strong>
                        22 <small>/ 212</small>
                      </strong>
                    </div>
                    <BorderLinearProgress
                      variant="determinate"
                      value={30}
                      style={{ width: "90%", height: "0.5vh" }}
                      className={styles.Statistics_progress}
                    />
                  </div>
                </Item>
              </Grid>
              <Grid item xs={4}>
                <Item className={styles.Statistics_box}>
                  <h1>Task Statistics</h1>
                  <div className={styles.task_rank}>
                    <div className={styles.total_rank}>
                      <h3>Total Tasks</h3>
                      <h4>385</h4>
                    </div>
                    <div className={styles.total_rank}>
                      <h3>Overdue Tasks</h3>
                      <h4>19</h4>
                    </div>
                  </div>
                  <div className={styles.progress_layer}>
                    <div className={styles.Completed}>30%</div>
                    <div className={styles.inprogress}>22%</div>
                    <div className={styles.onhold}>24%</div>
                    <div className={styles.pending}>21%</div>
                    <div className={styles.review}>10%</div>
                  </div>

                  <div className={styles.task_text}>
                    <h3>
                      Completed Tasks{" "}
                      <span className={styles.float_right}>166</span>
                    </h3>
                  </div>

                  <div className={styles.task_text}>
                    <h3>
                      Inprogress Tasks{" "}
                      <span className={styles.float_right}>115</span>
                    </h3>
                  </div>

                  <div className={styles.task_text}>
                    <h3>
                      On Hold Tasks{" "}
                      <span className={styles.float_right}>31</span>
                    </h3>
                  </div>

                  <div className={styles.task_text}>
                    <h3>
                      Pending Tasks{" "}
                      <span className={styles.float_right}>47</span>
                    </h3>
                  </div>

                  <div className={styles.task_text}>
                    <h3>
                      Review Tasks <span className={styles.float_right}>5</span>
                    </h3>
                  </div>
                </Item>
              </Grid>
              <Grid item xs={4}>
                <Item className={styles.Statistics_box}>
                  <h1>Today Absent</h1>
                  <div className={styles.absent_person}>
                    <img src={Absent_img} alt="Absent_img" />
                    <h3>Martin Lewis</h3>
                  </div>
                  <div className={styles.absent_date}>
                    <h3>4 Sep 2023</h3>
                    <span>Pending</span>
                    <p>Leave Date</p>
                  </div>

                  <div className={styles.absent_person}>
                    <img src={Absent_img} alt="Absent_img" />
                    <h3>Martin Lewis</h3>
                  </div>
                  <div className={styles.absent_date}>
                    <h3>4 Sep 2023</h3>
                    <span className={styles.absent_approved}>Approved</span>
                    <p>Leave Date</p>
                  </div>

                  <Button variant="text" className={styles.absent_loadbtn}>
                    Load More
                  </Button>
                </Item>
              </Grid>
            </Grid>
          </Box>
        </div>

        <div className={styles.bill_container}>
          <Box sx={{ width: "100%" }}>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={6}>
                <Item className={styles.invoice_text}>
                  <h1>Invoices</h1>
                  <div className={styles.invoice_head}>
                    <div className={styles.head_text}>
                      <h3>
                        Invoice Id <img src={ArowImg} alt="ArowImg" />
                      </h3>
                      <p>#INV-0001 </p>
                      <p>#INV-0002</p>
                      <p>#INV-0003</p>
                    </div>
                    <div className={styles.head_text}>
                      <h3>Client</h3>
                      <p> Global Technologies</p>
                      <p> Delta Infotech</p>
                      <p> Cream Inc</p>
                    </div>
                    <div className={styles.head_text}>
                      <h3>Due Date</h3>
                      <p>11 Mar 2023</p>
                      <p>8 Feb 2023</p>
                      <p>23 Jan 2023</p>
                    </div>
                    <div className={styles.head_text}>
                      <h3>Total</h3>
                      <p>$380</p>
                      <p>$500</p>
                      <p>$60</p>
                    </div>
                    <div className={styles.head_text}>
                      <h3>Status</h3>
                    </div>
                  </div>
                </Item>
              </Grid>
              <Grid item xs={6}>
                <Item className={styles.invoice_text}>
                  <h1>Payments</h1>
                  <div className={styles.invoice_head}>
                    <div className={styles.head_text}>
                      <h3>Invoice Id</h3>
                      <p>#INV-0001 </p>
                      <p>#INV-0002</p>
                      <p>#INV-0003</p>
                    </div>
                    <div className={styles.head_text}>
                      <h3>Client</h3>
                      <p> Global Technologies</p>
                      <p> Delta Infotech</p>
                      <p> Cream Inc</p>
                    </div>
                    <div className={styles.head_text}>
                      <h3>Payment Type</h3>
                      <p>Paypal</p>
                      <p>Paypal</p>
                      <p>Paypal</p>
                    </div>
                    <div className={styles.head_text}>
                      <h3>Paid Date</h3>
                      <p>11 Mar 2023</p>
                      <p>8 Feb 2023</p>
                      <p>23 Jan 2023</p>
                    </div>
                    <div className={styles.head_text}>
                      <h3>Paid Amount</h3>
                      <p>$380</p>
                      <p>$500</p>
                      <p>$60</p>
                    </div>
                  </div>
                </Item>
              </Grid>
            </Grid>
          </Box>
        </div>
      </div>
    </Box>
  );
};

export default Admin;
