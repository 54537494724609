import React, { useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { Box, Button, Dialog, DialogContent, FormControl, MenuItem, Select, SelectChangeEvent, TextField, Typography } from '@mui/material';
import styles from './ShiftList/ShiftList.module.scss'

interface addEditProps {
    heading: string;
    opendg: boolean;
    handleClose: () => void;
}
const AddEditShiftDg = ({ heading, opendg, handleClose }: addEditProps) => {
    const [project, setProject] = useState<string>('Office Management')
    const handleChangeProject = (event: SelectChangeEvent) => {
        setProject(event.target.value)
    }
    return (
        <Dialog
            open={opendg}
            onClose={handleClose}
            className={styles.dgwidth}

            sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "800px",
                    },
                },
            }}
        >
            <DialogContent
                sx={{
                    '&.MuiDialogContent-root': {
                        overflowX: 'hidden'
                    }
                }}>
                <FormControl sx={{
                    '&.MuiFormControl-root': {
                        width: "100%",
                    }
                }}>
                    <Box
                        className={styles.dialogDflex}>
                        <Typography
                            variant="h5"
                            sx={{ fontWeight: 500, margin: '.5rem' }}>
                            {heading}
                        </Typography>
                        <i onClick={handleClose}>
                            <CloseIcon />
                        </i>
                    </Box>
                    <Typography
                        variant="body2"
                        sx={{ marginTop: '.5rem' }}>
                        Project
                        <span>*</span>
                    </Typography>

                    <Select
                        value={project}
                        onChange={handleChangeProject}
                    >
                        <MenuItem value="Office Management">Office Management</MenuItem>
                        <MenuItem value='Project Management'>Project Management</MenuItem>
                        <MenuItem value='Video Calling App'>Video Calling App</MenuItem>
                        <MenuItem value='Hospital Administration'>Hospital Administration</MenuItem>
                    </Select>

                    <div className={styles.dialogDflex}>
                        <div>
                            <Typography
                                variant="body2"
                                sx={{ marginTop: '.5rem' }}>
                                Deadline
                                <span>*</span>
                            </Typography>
                            <LocalizationProvider
                                dateAdapter={AdapterDayjs} >
                                <DatePicker disabled />
                            </LocalizationProvider>
                        </div>
                        <div>
                            <Typography
                                variant="body2"
                                sx={{ marginTop: '.5rem' }}>
                                Total Hour
                                <span>*</span>
                            </Typography>
                            <TextField disabled />
                        </div>
                        <div>
                            <Typography
                                variant="body2"
                                sx={{ marginTop: '.5rem' }}>
                                Remaining Hour
                                <span>*</span>
                            </Typography>
                            <TextField disabled />
                        </div>
                    </div>
                    <div className={styles.dgDateHr}>
                        <div>
                            <Typography
                                variant="body2"
                                sx={{ marginTop: '.5rem' }}>
                                Date
                                <span>*</span>
                            </Typography >
                            <LocalizationProvider
                                dateAdapter={AdapterDayjs}>
                                <DatePicker
                                />
                                <i className={styles.dateicondg}><DateRangeIcon /></i>
                            </LocalizationProvider>
                        </div>
                        <div>
                            <Typography
                                variant="body2"
                                sx={{ marginTop: '.5rem' }}>
                                Remaining Hour
                                <span>*</span>
                            </Typography>
                            <TextField required />
                        </div>
                    </div>
                    <Typography
                        variant="body2"
                        sx={{ margin: '1rem 0rem' }}>
                        Description
                        <span>*</span>
                    </Typography>               
                    <Button
                        className={styles.dialogBoxBtn}
                    >Submit</Button>
                </FormControl>
            </DialogContent>
        </Dialog>
    )
}
export{AddEditShiftDg}