import {
    Box, FormControl, styled, Select, SelectChangeEvent, MenuItem,
    Pagination, Stack, Table, TableBody, TableCell, TableContainer,
    TableHead, TablePagination, TableRow, TableSortLabel, Typography,

} from '@mui/material'
import styles from './ShiftList.module.scss'
import React, { useMemo, useState } from 'react'
import { CustomPopover, DeleteDialog } from '../../AdminLeave/CustomDialogs';
import { visuallyHidden } from '@mui/utils';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { AddEditShiftDg } from '../ShiftSheduleDialog';


function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
    id: keyof Data;
    label: string;
}

const headCells: readonly HeadCell[] = [
    { id: 'id', label: '# ', },
    { id: 'shiftname', label: 'Shift Name', },
    { id: 'minStartTime', label: 'Min Start time', },
    { id: 'startTime', label: 'Start time', },
    { id: 'maxStartTime', label: 'Max Start time', },
    { id: 'minEndTime', label: 'Min End time', },
    { id: 'endTime', label: 'End time', },
    { id: 'maxEndTime', label: 'Max End time', },
    { id: 'breakTime', label: 'Break Time', },
    { id: 'status', label: 'Status', },
    { id: 'action', label: 'Action', },
];
interface EnhancedTableProps {
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler =
        (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

    return (
        <TableHead>
            <TableRow className={styles.tableHeading}>
                {headCells.map((headCell) => (
                    <TableCell
                        sx={{ fontWeight: 600 }}
                        align='left'
                        key={headCell.id}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const StyledTableRow = styled(TableRow)(() => ({
    '&:nth-of-type(odd)': {
        backgroundColor: "#e2e4e6",
    },
    '&:nth-of-type(even)': {
        backgroundColor: "white",
    },
}));

interface Data {
    id: number;
    shiftname: string;
    minStartTime: string;
    startTime: string;
    maxStartTime: string;
    minEndTime: string;
    endTime: string;
    maxEndTime: string;
    breakTime: string;
    status: string;
    action: string;
}

const createData = (
    id: number,
    shiftname: string,
    minStartTime: string,
    startTime: string,
    maxStartTime: string,
    minEndTime: string,
    endTime: string,
    maxEndTime: string,
    breakTime: string,
    status: string,
    action: string
): Data => ({
    id,
    shiftname,
    minStartTime,
    startTime,
    maxStartTime,
    minEndTime,
    endTime,
    maxEndTime,
    breakTime,
    status,
    action
});

const diffStatus = ['active', 'inactive']

const ShiftList: React.FC<{ open: boolean }> = ({ open }) => {
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<keyof Data>('id');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [openPopover, setOpenPopover] = useState<null | HTMLElement>(null);
    const [opendelete, setDelete] = useState<boolean>(false)
    const [AddShift, setAddShift] = useState<HTMLElement | null>(null)
    const [EditShift, setEditShift] = useState<HTMLElement | null>(null)
    const [selectStatus, setStatus] = useState<string>('active')
    const [popoverPosition, setPopoverPosition] = useState({ top: 0, left: 0 });
    const [editRowId, setEditRowId] = useState<number | null>(null);

    let rows: Data[] = [createData(1, 'Shift A', '08:00', '08:30', '09:00', '16:00', '16:30', '17:00', '00:30', 'active', '')];

    const clickToAssign = (event: React.MouseEvent<HTMLElement>) => {
        console.log('ckicked')
    }
    const clickOnShift = (event: React.MouseEvent<HTMLElement>) => {
        setAddShift(AddShift ? null : event.currentTarget);
        
    }

    const ChangeStatus = (event: SelectChangeEvent) => {
        setStatus(event.target.value);
    };

    //add Dialog
    const openAddDg = Boolean(AddShift)

    //for popover
    const openPop = Boolean(openPopover);
    const handleOpenPopover = (event: React.MouseEvent<HTMLElement>, id: number) => {
        const rect = event.currentTarget.getBoundingClientRect();
        setPopoverPosition({
            top: rect.bottom,
            left: rect.left
        });
        setEditRowId(id);
        setOpenPopover(openPopover ? null : event.currentTarget);
    };

    const openEdit = (event: React.MouseEvent<HTMLElement>) => {
        console.log(editRowId)
        setEditShift(EditShift ? null : event.currentTarget);
    };

    const openDeleteDg = () => {
        setDelete(true);
    }

    //edit dialog
    const openeditdg = Boolean(EditShift)

    //delete DialogBox
    const delateData = () => {

    };

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof Data,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleStackPaginationClick = (event: unknown | null, newPage: number) => {
        setPage(newPage - 1); // Adjust newPage to match zero-indexed page state
    };


    const visibleRows = useMemo(
        () =>
            stableSort(rows, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, page, rowsPerPage],
    );
    return (
        <>
            <Box
                className={` ${open ? styles.open : styles.sheduleList_Container}`}>
                <SheduleHeader heading='Shift List' clickToAdd={clickToAssign} clickOnShift={clickOnShift} />

                <Box sx={{ width: '100%' }}>
                    <div className={styles.tableHeader}>
                        <p>show</p>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={() => { }}
                            onRowsPerPageChange={() => { }}
                            sx={{
                                '.MuiTablePagination-spacer': { display: 'none' },
                                '.MuiToolbar-root': { paddingLeft: 0, minHeight: 0 },
                                '.MuiTablePagination-actions': { display: 'none' },
                                '.MuiTablePagination-displayedRows': { display: 'none' },
                                '.MuiTablePagination-selectLabel': { display: 'none' },
                            }}
                        />
                        <p>Entries</p>
                    </div>
                    <TableContainer>
                        <Table
                            sx={{ minWidth: 750 }}
                        >
                            <EnhancedTableHead
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                                rowCount={rows.length}
                            />
                            <TableBody>
                                {visibleRows.map((row) => {
                                    return (
                                        <StyledTableRow data-id={row.id}>
                                            <TableCell
                                                align='left'
                                                scope="row"
                                            >
                                                {row.id}
                                            </TableCell>
                                            <TableCell align="left">{row.startTime}</TableCell>
                                            <TableCell align="left">{row.minStartTime}</TableCell>
                                            <TableCell align="left">{row.startTime}</TableCell>
                                            <TableCell align="left">{row.maxStartTime}</TableCell>
                                            <TableCell align="left">{row.maxEndTime}</TableCell>
                                            <TableCell align="left">{row.endTime}</TableCell>
                                            <TableCell align="left">{row.maxEndTime}</TableCell>
                                            <TableCell align="left">{row.breakTime}</TableCell>
                                            <TableCell align="left">
                                                <FormControl>
                                                    <Select
                                                        sx={{
                                                            height: '2rem', borderRadius: '3rem',
                                                            '.MuiSelect-select': { display: 'flex' }
                                                        }}
                                                        value={selectStatus}
                                                        onChange={ChangeStatus}
                                                    >
                                                        {diffStatus.map((item: string, index: number) => (
                                                            <MenuItem
                                                                value={item}
                                                                key={index}
                                                            >
                                                                <RadioButtonCheckedIcon
                                                                    className={`${item === 'active' ? styles.active : ''}
                                                                      ${item === 'inactive' ? styles.inactive : ''}`
                                                                    }
                                                                />
                                                                {item}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </TableCell>

                                            <TableCell align="left">
                                                <i onClick={(e) => { handleOpenPopover(e, row.id) }}><MoreVertIcon /></i>
                                            </TableCell>
                                            <CustomPopover
                                                openPopover={openPop}
                                                closePopover={() => setOpenPopover(null)}
                                                openEdit={openEdit}
                                                openDeleteDg={openDeleteDg}
                                                position={popoverPosition}
                                            />
                                        </StyledTableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <div style={{ float: 'right' }}>
                        <Stack spacing={2}>
                            <Pagination
                                count={Math.ceil(rows.length / rowsPerPage)}
                                page={page + 1}
                                onChange={handleStackPaginationClick}
                                variant="outlined"
                                shape="rounded"
                                hidePrevButton
                                hideNextButton
                                className={styles.usepagination}
                            />
                        </Stack>
                    </div>
                </Box >
            </Box>

            <DeleteDialog
                heading='Delete Time Sheet'
                opendelete={opendelete}
                closeDeleteDg={() => setDelete(false)}
                delateData={delateData} />

                <AddEditShiftDg
                heading='Edit Shift' 
                opendg={openeditdg}
                handleClose={()=> setEditShift(null)}
                />
                <AddEditShiftDg
                heading='Add Shift' 
                opendg={openAddDg}
                handleClose={()=> setAddShift(null)}
                />
        </>
    )
}

export default ShiftList;

interface SheduleHeaderProp {
    heading: string,
    clickToAdd: (event: React.MouseEvent<HTMLElement>) => void,
    clickOnShift:(event: React.MouseEvent<HTMLElement>)=>void,
}
 const SheduleHeader = ({ heading, clickToAdd,clickOnShift }: SheduleHeaderProp) => {
    return (
        <div className={styles.alHeader}>
            <div>
                <Typography variant="h5">
                    {heading}
                </Typography>
                <ul className={styles.headingSubText}>
                    <li><a href="/dashboard">Dashboard </a></li>
                    <li><a href="#"> / Employee</a></li>
                    <li><a href="#"> / {heading}</a></li>
                </ul>
            </div>
            <div>
                <button
                    className={styles.hadingBtn}
                    onClick={clickToAdd}>
                    <span>+</span>
                    Assign Shifts
                </button>
                <button
                    className={styles.hadingBtn}
                    onClick={clickOnShift}>
                    <span>+</span>
                    Shifts
                </button>
            </div>
        </div>
    )
}
export {SheduleHeader}