import React from 'react';
import { Avatar } from '@mui/material';
import "./HeaderStyle.scss"

interface ActiveImageProps {
  src: string;
  alt: string;
  active: boolean;
  onClick?: () => void;
}


const ActiveImage: React.FC<ActiveImageProps> = ({ src, alt, active, onClick }) => {
  return (
    <div className='avtar'>
    <Avatar alt={alt} src={src} className={active ? 'active' : ''} sx={{ width: 34, height: 34 }} />
    </div>
  );
};

export default ActiveImage;
